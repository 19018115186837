/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDoctors = /* GraphQL */ `
  mutation CreateDoctors(
    $input: CreateDoctorsInput!
    $condition: ModelDoctorsConditionInput
  ) {
    createDoctors(input: $input, condition: $condition) {
      id
      title
      gender
      first_name
      middle_name
      last_name
      email
      languages
      preferred_language
      phones
      resume
      address
      specialties
      profile_status
      insurance_number
      professional_identification_number
      avatar_picture
      other_pictures
      prospection_email_status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDoctors = /* GraphQL */ `
  mutation UpdateDoctors(
    $input: UpdateDoctorsInput!
    $condition: ModelDoctorsConditionInput
  ) {
    updateDoctors(input: $input, condition: $condition) {
      id
      title
      gender
      first_name
      middle_name
      last_name
      email
      languages
      preferred_language
      phones
      resume
      address
      specialties
      profile_status
      insurance_number
      professional_identification_number
      avatar_picture
      other_pictures
      prospection_email_status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDoctors = /* GraphQL */ `
  mutation DeleteDoctors(
    $input: DeleteDoctorsInput!
    $condition: ModelDoctorsConditionInput
  ) {
    deleteDoctors(input: $input, condition: $condition) {
      id
      title
      gender
      first_name
      middle_name
      last_name
      email
      languages
      preferred_language
      phones
      resume
      address
      specialties
      profile_status
      insurance_number
      professional_identification_number
      avatar_picture
      other_pictures
      prospection_email_status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      type
      values
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      type
      values
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelSettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      type
      values
      createdAt
      updatedAt
      owner
    }
  }
`;
