import GoBackIcon from "@mui/icons-material/ChevronLeft";
import { AlertColor } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { I18n } from "aws-amplify";
import { useState } from "react";
import { useGlobalContext } from "../../commons/ContextProvider/ContextProvider";
import CustomSnackbar from "../../commons/CustomSnackbar";
import ChangePassword from "./ChangePassword";

const Settings = () => {
  const { setDisplaySettings } = useGlobalContext();

  const [snackBarMessage, setSnackBarMessage] = useState<{
    severity: AlertColor | undefined;
    message: string;
  } | null>(null);

  const handleGoToProfile = () => {
    if (setDisplaySettings) {
      setDisplaySettings(false);
    }
  };

  return (
    <Box m={2}>
      <Box my={1}>
        <Button
          // sx={{
          //   minWidth: "10%",
          //   minHeight: "5%",
          //   padding: ".7% .2%",
          // }}
          sx={{
            color: "rgb(31 192 180)",
          }}
          size="large"
          startIcon={<GoBackIcon />}
          onClick={handleGoToProfile}
        >
          {I18n.get("Back")}
        </Button>
      </Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <ChangePassword
            setSnackbarMessage={setSnackBarMessage}
            handleCancel={handleGoToProfile}
          />
        </Grid>
      </Grid>

      <CustomSnackbar
        open={!!snackBarMessage}
        autoHideDuration={5000}
        severity={snackBarMessage?.severity}
        onClose={() => setSnackBarMessage(null)}
        message={snackBarMessage?.message}
      />
    </Box>
  );
};

export default Settings;
