import Box from "@mui/material/Box";
import OfficeImages from "./OfficeImages";
import ProfilePhoto from "./ProfilePhoto";

const ProfileImages = () => {
  return (
    <>
      <Box my={1}>
        <ProfilePhoto />
      </Box>
      <Box my={1}>
        <OfficeImages />
      </Box>
    </>
  );
};

export default ProfileImages;
