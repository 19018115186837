import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { getAccountProfileImageFolder } from "../../utils/account";
import { useGlobalContext } from "../ContextProvider/ContextProvider";
import Avatar from "@mui/material/Avatar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const UserAvatar = () => {
  const [profileUrl, setProfileUrl] = useState("");
  //   const [profileKey, setProfileKey] = useState("");

  const { account, profileUrl: profileContextUrl } = useGlobalContext();

  const profileImageFolder = getAccountProfileImageFolder(account.id);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const fetchProfileUrl = async () => {
    try {
      const results = await Storage.list(`${profileImageFolder}/`, {
        level: "private",
      });
      if (results) {
        const profileResult = results[0];

        if (profileResult.key) {
          // setProfileKey(profileResult.key);
          const storedProfileUrl = await Storage.get(profileResult.key, {
            level: "private",
          });

          setProfileUrl(storedProfileUrl);
        }
      }
    } catch {}
  };

  useEffect(() => {
    fetchProfileUrl();
  }, []);

  useEffect(() => {
    console.log("!! profile context", profileContextUrl);

    setProfileUrl(profileContextUrl);
  }, [profileContextUrl]);

  return (
    <>
      <Avatar
        sx={{
          width: isMobile ? 24 : 32,
          height: isMobile ? 24 : 32,
          backgroundColor: "transparent",
          border: profileUrl
            ? "2px transparent transparent"
            : "2px solid white",
        }}
        src={Boolean(profileUrl) ? profileUrl : undefined}
      />
    </>
  );
};

export default UserAvatar;
