import { createContext, FC, useContext, useState } from "react";

export interface AccountContextState {
  id: string;
  email: string;
  title: string;
  firstName: string;
  lastName: string;
}

const ACCOUNT_CONTEXT: AccountContextState = {
  id: "",
  email: "",
  title: "",
  firstName: "",
  lastName: "",
};

export interface ContextState {
  displaySettings: boolean;
  account: AccountContextState;
  profileUrl: string;
  setDisplaySettings?: (payload: boolean) => void;
  setAccount?: (data: AccountContextState) => void;
  setProfileUrl?: (payload: string) => void;
}

export const DEFAULT_CONTEXT: ContextState = {
  displaySettings: false,
  account: { ...ACCOUNT_CONTEXT },
  profileUrl: "",
};

export const GlobalContext = createContext(DEFAULT_CONTEXT);

export const useGlobalContext = () => useContext(GlobalContext);

const ContextProvider: FC = ({ children }) => {
  // const [contextState, setContextState] = useState(DEFAULT_CONTEXT);
  const [displaySettings, setLocalDisplaySettings] = useState(false);
  const [account, setLocalAccount] = useState(ACCOUNT_CONTEXT);
  const [profileUrl, setLocaleProfileUrl] = useState("");

  const setDisplaySettings = (payload: boolean) => {
    setLocalDisplaySettings(payload);
  };

  const setAccount = (account: AccountContextState) => {
    setLocalAccount({ ...account });
  };

  const setProfileUrl = (payload: string) => {
    setLocaleProfileUrl(payload);
  };

  return (
    <GlobalContext.Provider
      value={{
        displaySettings,
        account,
        profileUrl,
        setDisplaySettings,
        setAccount,
        setProfileUrl,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default ContextProvider;
