import { I18n } from "aws-amplify";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";

import mobileAppScreens from "./assets/mobile-app-screens.png";

const Advantages = () => {
  return (
    <Box className={"practitionerVisibilityContainer"}>
      <Box className={"practitionerVisibilitySubContainer"}>
        <Typography variant="h3" className={"mobilePractitionerVisibilityText"}>
          {I18n.get(
            "Vous êtes visible auprès des voyageurs en fonction de votre"
          )}{" "}
          <span className="mediumFontWeight">
            {I18n.get("géolocalisation, ")}
          </span>
          {I18n.get("des ")}
          <span className="mediumFontWeight">
            {I18n.get("tarifs pratiqués, ")}
          </span>{" "}
          {I18n.get("de vos ")}{" "}
          <span className="mediumFontWeight">
            {I18n.get("langues parlées, ")}
          </span>{" "}
          {I18n.get("de vos ")}
          <span className="mediumFontWeight">
            {I18n.get("disponibilités, ")}
          </span>{" "}
          {I18n.get("de vos ")}
          <span className="mediumFontWeight">
            {I18n.get("qualifications.")}
          </span>
        </Typography>
        <Box className={"desktopPractitionerVisibilityTextContainer"}>
          <Typography variant="h3">
            <span className="nowrap">
              {I18n.get("Vous êtes visible auprès des").toUpperCase()}
            </span>
            <br />
            {I18n.get("voyageurs en fonction :").toUpperCase()}
          </Typography>
          <List className={"visibilityList"}>
            <ListItem>
              <ListItemText>
                {"| "}
                {I18n.get("De votre")}{" "}
                <span className="mediumFontWeight">
                  {I18n.get("géolocalisation")}
                </span>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {"| "}
                {I18n.get("Des")}{" "}
                <span className="mediumFontWeight">
                  {I18n.get("tarifs pratiqués")}
                </span>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText sx={{ whiteSpace: "nowrap" }}>
                {"| "}
                {I18n.get("De vos")}{" "}
                <span className="mediumFontWeight">
                  {I18n.get("langues parlées")}
                </span>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {"| "}
                {I18n.get("De vos")}{" "}
                <span className="mediumFontWeight">
                  {I18n.get("disponibilités")}
                </span>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {"| "}
                {I18n.get("De vos")}{" "}
                <span className="mediumFontWeight">
                  {I18n.get("qualifications")}
                </span>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
        <Box
          component="img"
          alt=""
          src={mobileAppScreens}
          className={"mobileAppScreens"}
        />
      </Box>
    </Box>
  );
};

export default Advantages;
