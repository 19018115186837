import { isGeofence } from "./utils";
import { COLOR_BLACK } from "./constants";
const FILL_OPACITY = 0.3;
const BORDER_OPACITY = 0.5;
const BORDER_WIDTH = 4;
/**
 * DrawPoints utility function for adding points to a map based on coordinate data or a FeatureCollection. Will add clustered points and styled markers by default with options for popups and other styles
 */
export function drawGeofences(sourceName, data, map, options = {}) {
    var _a, _b, _c, _d, _e;
    if (!map ||
        typeof map.addSource !== "function" ||
        typeof map.addLayer !== "function") {
        throw new Error("Please use a maplibre map");
    }
    /*
     * Convert data passed in as coordinates into features
     */
    const features = getGeofenceFeaturesFromData(data);
    /*
     * Data source for features
     */
    const sourceId = `${sourceName}-source`;
    map.addSource(sourceId, {
        type: "geojson",
        data: {
            type: "FeatureCollection",
            features,
        },
        generateId: true,
    });
    /*
     * Draw ui layers for source data
     */
    // Add a new layer to visualize the polygon.
    const fillLayerId = `${sourceName}-fill-layer`;
    map.addLayer({
        id: fillLayerId,
        type: "fill",
        source: sourceId,
        layout: {
            visibility: "visible",
        },
        paint: {
            "fill-color": (_a = options.fillColor) !== null && _a !== void 0 ? _a : COLOR_BLACK,
            "fill-opacity": (_b = options.fillOpacity) !== null && _b !== void 0 ? _b : FILL_OPACITY,
        },
    });
    // Add a black outline around the polygon.
    const outlineLayerId = `${sourceName}-outline-layer`;
    map.addLayer({
        id: outlineLayerId,
        type: "line",
        source: sourceId,
        layout: {
            visibility: "visible",
        },
        paint: {
            "line-color": (_c = options.borderColor) !== null && _c !== void 0 ? _c : COLOR_BLACK,
            "line-opacity": (_d = options.borderOpacity) !== null && _d !== void 0 ? _d : BORDER_OPACITY,
            "line-width": (_e = options.borderWidth) !== null && _e !== void 0 ? _e : BORDER_WIDTH,
        },
    });
    // utility function for setting layer visibility to none
    const hide = () => {
        map.setLayoutProperty(fillLayerId, "visibility", "none");
        map.setLayoutProperty(outlineLayerId, "visibility", "none");
    };
    // utility function for setting layer visibility to visible
    const show = () => {
        map.setLayoutProperty(fillLayerId, "visibility", "visible");
        map.setLayoutProperty(outlineLayerId, "visibility", "visible");
    };
    return { sourceId, outlineLayerId, fillLayerId, show, hide };
}
const getGeofenceFeaturesFromData = (data) => {
    const features = data.map((item) => {
        const coordinates = isGeofence(item) ? item.geometry.polygon : item;
        return {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates,
            },
            properties: {},
        };
    });
    return features;
};
