import { I18n } from "aws-amplify";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

import rocket from "./assets/rocket.svg";
import communication from "./assets/communication.svg";
import practitionerGroup from "./assets/practitioner-group.svg";
import marketplace from "./assets/marketplace.svg";
import payment from "./assets/payment.svg";
import earth from "./assets/earth.svg";

const Advantages = () => {
  return (
    <Box className={"advantageContainerDecoration"}>
      <Box className={"mobileAdvantageContainerDecoration"}>
        <Typography
          mt={8}
          variant="h3"
          sx={{
            alignSelf: "center",
          }}
          className={"advantagesContainerHeader mediumFontWeight"}
        >
          {I18n.get("Vos avantages avec Bakup :").toUpperCase()}
        </Typography>
        <Box className={"advantagesContainer"}>
          <Box mt={7} className={"advantagesRowContainer"}>
            <Card elevation={0} className={"advantageCard cardOne"}>
              <div
                className="cardMediaDiv"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1%",
                }}
              >
                <CardMedia
                  component="img"
                  image={practitionerGroup}
                  alt=""
                  className={"advantageCardMedia practitionerGroup"}
                />
              </div>
              <CardContent className={"advantageCardContent"}>
                <Typography variant="body1">
                  <span className="mediumFontWeight">
                    {I18n.get("35 spécialités")}
                  </span>
                  <br />
                  <span>{I18n.get("référencées sur la plateforme")}</span>
                </Typography>
              </CardContent>
            </Card>

            <Card className={"advantageCard cardTwo"} elevation={0}>
              <div
                className="cardMediaDiv"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1%",
                }}
              >
                <CardMedia
                  component="img"
                  image={rocket}
                  alt=""
                  className={"advantageCardMedia rocket"}
                />
              </div>
              <CardContent className={"advantageCardContent "}>
                <Typography variant="body1">
                  <span className="mediumFontWeight">
                    {I18n.get("Gagner en visibilité auprès ")}
                  </span>
                  <br /> {I18n.get("des acteurs du tourisme")}
                </Typography>
              </CardContent>
            </Card>
            <Card elevation={0} className={"advantageCard cardThree"}>
              <div
                className="cardMediaDiv"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1%",
                }}
              >
                <CardMedia
                  component="img"
                  image={marketplace}
                  alt=""
                  className={"advantageCardMedia marketplace"}
                />
              </div>
              <CardContent className={"advantageCardContent"}>
                <Typography variant="body1">
                  <span className="mediumFontWeight">
                    {I18n.get("Aider les voyageurs")}
                  </span>{" "}
                  {I18n.get("à")}
                  <br />
                  {I18n.get("profiter sereinement de leur séjour")}
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box mt={1} mb={7} className={"advantagesRowContainer"}>
            <Card elevation={0} className={"advantageCard cardFour"}>
              <div
                className="cardMediaDiv"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1%",
                }}
              >
                <CardMedia
                  component="img"
                  image={earth}
                  alt=""
                  className={"advantageCardMedia earth"}
                />
              </div>
              <CardContent className={"advantageCardContent"}>
                <Typography variant="body1">
                  <span className="mediumFontWeight">
                    {I18n.get("Améliorer l'accès aux soins")}
                  </span>{" "}
                  <br />
                  {I18n.get("sur votre secteur auprès des voyageurs et")} <br />
                  {I18n.get("de désengorger les services d'urgences")}
                </Typography>
              </CardContent>
            </Card>

            <Card elevation={0} className={"advantageCard cardFive"}>
              <div
                className="cardMediaDiv"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1%",
                }}
              >
                <CardMedia
                  component="img"
                  image={communication}
                  alt=""
                  className={"advantageCardMedia networkMedia"}
                />
              </div>
              <CardContent className={"advantageCardContent"}>
                <Typography variant="body1">
                  {I18n.get("Développer ")}{" "}
                  <span className="mediumFontWeight">
                    {I18n.get("votre réseau")}
                  </span>
                </Typography>
              </CardContent>
            </Card>
            <Card elevation={0} className={"advantageCard sixthCard"}>
              <div
                className="cardMediaDiv"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1%",
                }}
              >
                <CardMedia
                  component="img"
                  image={payment}
                  alt=""
                  className={"advantageCardMedia payment"}
                />
              </div>
              <CardContent className={"advantageCardContent"}>
                <Typography variant="body1">
                  <span className="mediumFontWeight">
                    {I18n.get("Intégrer une plateforme 100% gratuite")}
                  </span>{" "}
                  <br />
                  {I18n.get("(incluant la téléconsultation à venir)")}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Advantages;
