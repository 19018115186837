import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Alert, { AlertColor } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Auth, I18n } from "aws-amplify";
import { FC, useState } from "react";

import "./ChangePassword.css";
const INITIAL_PASSWORD = {
  current: "",
  new: "",
  newConfirm: "",
};
const INITIAL_DISPLAY = {
  current: false,
  new: false,
  newConfirm: false,
};

const StyledPaper = styled(Paper)(() => ({
  padding: 24,
}));

interface ChangePasswordProps {
  setSnackbarMessage: React.Dispatch<
    React.SetStateAction<{
      severity: AlertColor | undefined;
      message: string;
    } | null>
  >;
  handleCancel(): void;
}

const ChangePassword: FC<ChangePasswordProps> = (props) => {
  const { setSnackbarMessage, handleCancel } = props;

  const [password, setPassword] = useState({
    ...INITIAL_PASSWORD,
  });
  const [showPassword, setShowPassword] = useState({
    ...INITIAL_DISPLAY,
  });
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, password.current, password.new);
      setSnackbarMessage({
        message: I18n.get("Password updated successfully"),
        severity: "success",
      });
      setPassword({ ...INITIAL_PASSWORD });
      setShowPassword({ ...INITIAL_DISPLAY });
    } catch (e: any) {
      const message = e.message ? e.message : "Error while updating password";

      setSnackbarMessage({
        message: I18n.get(message),
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
    if (name === "newConfirm") {
      setPasswordNotMatch(value !== password.new && Boolean(value.length));
    } else if (name === "new" && Boolean(password.newConfirm)) {
      setPasswordNotMatch(
        value !== password.newConfirm && Boolean(value.length)
      );
    }
  };

  const handleToggleShowPassword = (toToggle: string) => () => {
    switch (toToggle) {
      case "current":
        return setShowPassword({
          ...showPassword,
          [toToggle]: !showPassword.current,
        });
      case "new":
        return setShowPassword({
          ...showPassword,
          [toToggle]: !showPassword.new,
        });
      default:
        return setShowPassword({
          ...showPassword,
          [toToggle]: !showPassword.newConfirm,
        });
    }
  };

  return (
    <StyledPaper elevation={4}>
      <Box mb={2}>
        <Typography variant="h6">{I18n.get("Change password")}</Typography>
        <Divider />
      </Box>
      <Alert severity="warning" sx={{ mb: 1 }}>
        <div>
          {I18n.get(
            "Require at least one uppercase letter from Latin alphabet (A–Z)"
          )}
        </div>
        <div>
          {I18n.get(
            "Require at least one lowercase letter from Latin alphabet (a–z)"
          )}
        </div>
        <div>{I18n.get("Require at least one number")}</div>
        <div>
          {I18n.get(
            "Require at least one nonalphanumeric character ! @ # $ % ^ & * ( ) _ + - = [ ] {} | '"
          )}
        </div>
      </Alert>
      <form onSubmit={handleSubmit}>
        <FormControl variant="outlined" fullWidth required sx={{ my: 1 }}>
          <InputLabel htmlFor="current-password">
            {I18n.get("Current Password")}
          </InputLabel>
          <OutlinedInput
            id="current-password"
            type={showPassword.current ? "text" : "password"}
            name="current"
            value={password.current}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle current password visibility"
                  onClick={handleToggleShowPassword("current")}
                  edge="end"
                  sx={{ marginRight: "1%" }}
                >
                  {showPassword.current ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label={I18n.get("Current Password")}
            // Try to prevent auto fill password
            autoComplete="new-password"
            inputProps={{
              autoComplete: "new-password",
            }}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth required sx={{ my: 1 }}>
          <InputLabel htmlFor="new-password">
            {I18n.get("New Password")}
          </InputLabel>
          <OutlinedInput
            id="new-password"
            type={showPassword.new ? "text" : "password"}
            name="new"
            value={password.new}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle new visibility"
                  onClick={handleToggleShowPassword("new")}
                  edge="end"
                  sx={{ marginRight: "1%" }}
                >
                  {showPassword.new ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label={I18n.get("New Password")}
            // Try to prevent auto fill password
            autoComplete="new-password"
            inputProps={{
              autoComplete: "new-password",
            }}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth required sx={{ my: 1 }}>
          <InputLabel htmlFor="confirm-new-password">
            {I18n.get("Confirm New Password")}
          </InputLabel>
          <OutlinedInput
            id="confirm-new-password"
            type={showPassword.newConfirm ? "text" : "password"}
            name="newConfirm"
            value={password.newConfirm}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={handleToggleShowPassword("newConfirm")}
                  edge="end"
                  sx={{ marginRight: "1%" }}
                >
                  {showPassword.newConfirm ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label={I18n.get("Confirm New Password")}
            // Try to prevent auto fill password
            autoComplete="new-password"
            inputProps={{
              autoComplete: "new-password",
            }}
          />
          {passwordNotMatch && (
            <FormHelperText error>
              {I18n.get("Passwords not matches")}
            </FormHelperText>
          )}
        </FormControl>

        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Box>
            <Button
              variant="contained"
              color="inherit"
              onClick={handleCancel}
              sx={{ mr: 1 }}
            >
              {I18n.get("Cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "rgb(31 192 180)",
                minWidth: "17%",
                "&:hover": {
                  color: "white",
                  backgroundColor: "rgb(31 192 180)",
                },
              }}
              disabled={loading}
            >
              {I18n.get("Submit")}
            </Button>
          </Box>
        </Box>
      </form>
    </StyledPaper>
  );
};

export default ChangePassword;
