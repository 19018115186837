export function isCoordinates(array) {
    return (Array.isArray(array) &&
        typeof array[0] === "number" &&
        typeof array[1] === "number");
}
export function isCoordinatesArray(array) {
    return isCoordinates(array[0]);
}
export function isNamedLocation(object) {
    return (object &&
        Array.isArray(object.coordinates) &&
        typeof object.coordinates[0] === "number" &&
        typeof object.coordinates[1] === "number");
}
export function isNamedLocationArray(array) {
    return isNamedLocation(array[0]);
}
export function isGeofence(object) {
    return (object &&
        typeof object.id === "string" &&
        typeof object.geometry === "object");
}
export function isGeofenceArray(array) {
    return Array.isArray(array) && isGeofence(array[0]);
}
export function isGeoJsonSource(source) {
    return source.type === "geojson";
}
export const strHasLength = (str) => typeof str === "string" && str.length > 0;
export const getFeaturesFromData = (data) => {
    let features;
    if (isCoordinatesArray(data)) {
        features = data.map((point) => {
            return {
                type: "Feature",
                geometry: { type: "Point", coordinates: point },
                properties: { place_name: `Coordinates,${point}` },
            };
        });
    }
    else if (isNamedLocationArray(data)) {
        features = data.map((location) => {
            return {
                type: "Feature",
                geometry: { type: "Point", coordinates: location.coordinates },
                properties: { title: location.title, address: location.address },
            };
        });
    }
    else {
        features = data;
    }
    return features;
};
export const urlEncodePeriods = (str) => {
    return str.replace(/\./g, "%2E");
};
