export const COLOR_WHITE = "#fff";
export const COLOR_BLACK = "#000";
export const MARKER_COLOR = "#5d8aff";
export const ACTIVE_MARKER_COLOR = "#ff9900";
export const POPUP_BORDER_COLOR = "#0000001f";
export const LOCATION_MARKER = "M24.8133 38.533C18.76 31.493 13 28.8264 13 20.8264C13.4827 14.9864 16.552 9.67169 21.368 6.33302C33.768 -2.26165 50.824 5.78902 52.0667 20.8264C52.0667 28.613 46.5733 31.6797 40.6533 38.373C32.4933 47.5464 35.4 63.093 32.4933 63.093C29.72 63.093 32.4933 47.5464 24.8133 38.533ZM32.4933 8.23969C26.5573 8.23969 21.7467 13.0504 21.7467 18.9864C21.7467 24.9224 26.5573 29.733 32.4933 29.733C38.4293 29.733 43.24 24.9224 43.24 18.9864C43.24 13.0504 38.4293 8.23969 32.4933 8.23969Z";
export const OLD_MARKER = "M30 16C30 18.5747 29.1348 21.3832 27.7111 24.2306C26.2947 27.0635 24.3846 29.8177 22.4383 32.2506C20.4964 34.678 18.5493 36.7473 17.0858 38.2108C16.6828 38.6138 16.3174 38.9699 16 39.2739C15.6826 38.9699 15.3172 38.6138 14.9142 38.2108C13.4507 36.7473 11.5036 34.678 9.56174 32.2506C7.61543 29.8177 5.70531 27.0635 4.28885 24.2306C2.86518 21.3832 2 18.5747 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16Z";
// Map styles exist due to an issue with Amazon Location Service not supporting the default set of maplibre fonts
export var MAP_STYLES;
(function (MAP_STYLES) {
    MAP_STYLES["ESRI_TOPOGRAPHIC"] = "VectorEsriTopographic";
    MAP_STYLES["ESRI_STREETS"] = "VectorEsriStreets";
    MAP_STYLES["ESRI_LIGHT_GRAY"] = "VectorEsriLightGrayCanvas";
    MAP_STYLES["ESRI_DARK_GRAY"] = "VectorEsriDarkGrayCanvas";
    MAP_STYLES["ESRI_NAVIGATION"] = "VectorEsriNavigation";
    MAP_STYLES["HERE_BERLIN"] = "VectorHereBerlin";
})(MAP_STYLES || (MAP_STYLES = {}));
export const FONT_DEFAULT_BY_STYLE = {
    [MAP_STYLES.ESRI_TOPOGRAPHIC]: "Noto Sans Regular",
    [MAP_STYLES.ESRI_STREETS]: "Arial Regular",
    [MAP_STYLES.ESRI_LIGHT_GRAY]: "Ubuntu Regular",
    [MAP_STYLES.ESRI_DARK_GRAY]: "Ubuntu Regular",
    [MAP_STYLES.ESRI_NAVIGATION]: "Arial Regular",
    [MAP_STYLES.HERE_BERLIN]: "Fira GO Regular",
};
