import { AlertProps } from "@aws-amplify/ui-react";
import { Alert, AlertColor, Snackbar, SnackbarProps } from "@mui/material";

interface IProps {
    severity: AlertColor | undefined;
    onClose: () => void
}
const CustomSnackbar
    = (props: IProps & SnackbarProps & AlertProps) => {
        const { ...otherProps } = props

        return (
            <>
                <Snackbar
                    sx={{ width: '100%' }}
                    autoHideDuration={3000}
                    {...otherProps}
                >
                    <Alert severity={props.severity}>{props.message}</Alert>
                </Snackbar>
            </>
        );
    };

export default CustomSnackbar
    ;
