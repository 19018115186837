import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { Geo, I18n } from "aws-amplify";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

const SEARCH_OPTION = {
  // countries: ["FRA"], // Alpha-3 country codes
  maxResults: 5, // 50 is the max and the default
  // biasPosition: [0, 0],
};

export default function LocationSearchInput(props: any) {
  const {
    selectedLocationData,
    location,
    setSelectedLocationData,
    handleFlyClick,
    isSubmitted,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);

  const [fieldsUpdated, setFieldsUpdated] = useState({
    location: false,
  });
  const [formErrors, setFormErrors] = useState({
    location: " ",
    locationValid: false,
  });

  const [searchInputState, setSearchInputState] = useState(
    (selectedLocationData && selectedLocationData.label) || ""
  );
  const [isLocationError, setIsLocationError] = useState(false);

  useEffect(() => {
    if (isSubmitted && !Boolean(searchInputState)) {
      setIsLocationError(true);
    }
  }, [isSubmitted]);

  function handleLocationOnBlur(e: any) {
    const { value } = e.target;
    setFieldsUpdated({
      ...fieldsUpdated,
      location: true,
    });

    if (!value) {
      setFormErrors({
        ...formErrors,
        location: I18n.get("Location required"),
        locationValid: false,
      });
    } else {
      setFormErrors({
        ...formErrors,
        location: "",
        locationValid: true,
      });
    }
  }

  useEffect(() => {
    setFieldsUpdated({ ...fieldsUpdated, location: true });
  }, [location]);

  const addressRef = useRef<any>(0);

  async function handleSearchInputChange(
    event: any,
    value: string,
    reason: any
  ) {
    setSearchInputState(value);

    setIsLocationError(!Boolean(value));

    if (addressRef.current) {
      clearTimeout(addressRef.current);
    }
    addressRef.current = setTimeout(async () => {
      if (value !== "") {
        try {
          setLoading(true);
          const results = await Geo.searchByText(value, SEARCH_OPTION);
          setOptions(results);
          // console.log(`+++ results format`, results);
        } catch (error) {
          console.error("!! error setting options >>", error);
        } finally {
          setLoading(false);
        }
      }
    }, 600);
    // handleLocationOnBlur(event);
  }

  const handleSelectAddress = (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: any
  ) => {
    setSelectedLocationData(value);
    handleFlyClick(value.geometry.point);
  };

  return (
    <>
      <Autocomplete
        id="locationAutoComplete"
        sx={{ mb: 2 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        noOptionsText={I18n.get("No options")}
        value={selectedLocationData}
        options={options}
        getOptionLabel={(option) => option.label}
        // loading={loading}
        // loadingText={I18n.get("Loading...")} // Hidden by list (use circular progress)
        clearOnBlur={false}
        clearOnEscape={false}
        onChange={handleSelectAddress}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        filterOptions={(options, state) => options} // IMPORTANT: Do not filter results
        inputValue={searchInputState} // Needed to make label on top while using onInputChange
        onInputChange={handleSearchInputChange} // Use in pair with inputValue
        renderInput={(params) => (
          <TextField
            {...params}
            required={true}
            label={I18n.get("Location")}
            // onChange={handleSearchInputChange} // NOTE: avoid to use with onChange into Autocomplete
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            // helperText={formErrors.locationValid ? " " : formErrors.location}
            // onBlur={(e) => handleLocationOnBlur(e)}
            // error={
            //   fieldsUpdated.location === true &&
            //   formErrors.location !== "" &&
            //   formErrors.location !== " " &&
            //   !location
            //     ? true
            //     : false
            // }
            helperText={
              isLocationError ? I18n.get("Location required") : undefined
            }
            error={isLocationError}
          />
        )}
      />

      {/* <FormHelperText id="location-helper-text" className="errorText">
        {" "}
      </FormHelperText> */}
    </>
  );
}
