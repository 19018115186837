/* eslint-disable @typescript-eslint/no-use-before-define */
import { Flex, Text } from "@aws-amplify/ui-react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { I18n } from "aws-amplify";
import maplibregl from "maplibre-gl";
import { createMap } from "maplibre-gl-js-amplify";
import "maplibre-gl/dist/maplibre-gl.css";
import React, { useEffect, useRef, useState } from "react";
import LocationSearchInput from "../../Geo/Maps/LocationSearchInput";
import "../../Geo/Maps/Maps.css";
import {
  getLanguages,
  getSpecialities,
  updateCurrentDoctor,
} from "../consumers";
import CountrySelector from "../CountrySelector/CoutrySelector";
import { IAdress } from "../Profile";
import HelpIcon from "@mui/icons-material/Help";

import "./EditProfileProfessionalForm.css";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface IProps {
  lang: string;
  userId: string;
  // userAlreadyExist: boolean;
  additionalAddressInfos: string;
  setAdditionalAddressInfos: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  alternativePhone: string;
  setAlternativePhone: React.Dispatch<React.SetStateAction<string>>;
  // specialities: ISpeciality[];
  specialities: string[];
  setSpecialities: React.Dispatch<React.SetStateAction<string[]>>;
  languages: string[];
  setLanguages: React.Dispatch<React.SetStateAction<string[]>>;
  professionalNumber: string;
  setProfessionalNumber: React.Dispatch<React.SetStateAction<string>>;
  insurance: string;
  setInsurance: React.Dispatch<React.SetStateAction<string>>;
  resume: string;
  setResume: React.Dispatch<React.SetStateAction<string>>;
  countryCodePhoneNumber: string;
  countryCodeAlternativePhoneNumber: string;
  setCountryCodePhoneNumber: React.Dispatch<React.SetStateAction<any>>;
  setCountryCodeAlternativePhoneNumber: React.Dispatch<
    React.SetStateAction<any>
  >;
  selectedLocationData: IAdress;
  setSelectedLocationData: React.Dispatch<React.SetStateAction<IAdress>>;
  preferredLanguage: string;
  setPreferredLanguage: React.Dispatch<React.SetStateAction<string>>;
  setFormsValid: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitted: boolean;
}

const EditProfileProfessionalForm = (props: IProps) => {
  const {
    lang,
    userId,
    // userAlreadyExist,
    additionalAddressInfos,
    setAdditionalAddressInfos,
    phoneNumber,
    setPhoneNumber,
    alternativePhone,
    setAlternativePhone,
    specialities,
    setSpecialities,
    languages,
    setLanguages,
    insurance,
    setInsurance,
    professionalNumber,
    setProfessionalNumber,
    resume,
    setResume,
    countryCodePhoneNumber,
    countryCodeAlternativePhoneNumber,
    setCountryCodeAlternativePhoneNumber,
    setCountryCodePhoneNumber,
    selectedLocationData,
    setSelectedLocationData,
    preferredLanguage,
    setPreferredLanguage,
    setFormsValid,
    isSubmitted,
  } = props;

  const [langChoices, setLangChoices] = useState<string[]>([]);
  const [specialitiyChoices, setSpecialitiyChoices] = useState<string[]>([]);
  // const [localNewSpecialities, setLocalNewSpecialities] = useState<string[]>(
  //   []
  // );
  const [mapVisible, setMapVisible] = useState(true);

  const [mapMarker, setMapMarker] = useState<any>(null);
  const [initPosition, setInitPostion] = useState(false);

  const fieldsUpdated = useRef({
    location: false,
    additionalAddressInfos: false,
    phoneNumber: false,
    alternativePhone: false,
    specialities: false,
    languages: false,
    insurance: false,
    professionalNumber: false,
    resume: false,
    countryCodePhoneNumber: false,
    countryCodeAlternativePhoneNumber: false,
    preferredLanguage: false,
  });

  const [formErrors, setFormErrors] = useState({
    location: " ",
    additionalAddressInfos: " ",
    phoneNumber: " ",
    alternativePhone: " ",
    specialities: " ",
    languages: " ",
    insurance: " ",
    professionalNumber: " ",
    resume: " ",
    countryCodePhoneNumber: " ",
    countryCodeAlternativePhoneNumber: " ",
    preferredLanguage: "",
    locationValid: false,
    additionalAddressInfosValid: false,
    phoneNumberValid: false,
    alternativePhoneValid: false,
    specialitiesValid: false,
    languagesValid: false,
    insuranceValid: false,
    professionalNumberValid: false,
    resumeValid: false,
    countryCodePhoneNumberValid: false,
    countryCodeAlternativePhoneNumberValid: false,
    preferredLanguageValid: false,
  });

  const [fieldsError, setFieldsError] = useState({
    phoneNumber: false,
    specialities: false,
    languages: false,
    countryCodePhoneNumber: false,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isSubmitted) {
      setFieldsError({
        phoneNumber: !Boolean(phoneNumber),
        specialities: !Boolean(specialities.length),
        languages: !Boolean(languages.length),
        countryCodePhoneNumber: !Boolean(countryCodePhoneNumber),
      });
    }
  }, [isSubmitted]);

  // const nameRegEx = /[^A-Za-z -]/g; // Match anything that is not A-Z, a-z, or space
  // const locationRegEx = /[^A-Za-z -]/g;
  // const phoneNumberRegEx = /[^0-9+- .()]/g;

  function handleOnBlur(e: any, fieldName: string, thirdParameter?: any[]) {
    const { value } = e.target;

    switch (fieldName) {
      case "location":
        fieldsUpdated.current.location = true;

        if (!value) {
          setFormErrors({
            ...formErrors,
            location: I18n.get("Location required"),
            locationValid: false,
          });
        }
        // else if (nameRegEx.test(value)) {
        //   setFormErrors({
        //     ...formErrors,
        //     location: I18n.get(
        //       "Please use allowed characters: a-z, A-Z, '-' and spaces."
        //     ),
        //     locationValid: false,
        //   });
        // }
        else {
          setFormErrors({
            ...formErrors,
            location: " ",
            locationValid: true,
          });
        }

        break;
      //
      case "additionalAddressInfos":
        fieldsUpdated.current.additionalAddressInfos = true;

        if (value.length > 250) {
          setFormErrors({
            ...formErrors,
            additionalAddressInfos: I18n.get(
              "Additional address informations too long" //TODO
            ),
            additionalAddressInfosValid: false,
          });
        } else {
          setFormErrors({
            ...formErrors,
            additionalAddressInfos: "",
            additionalAddressInfosValid: true,
          });
        }
        break;

      case "phoneNumber":
        fieldsUpdated.current.phoneNumber = true;

        if (!value) {
          setFormErrors({
            ...formErrors,
            phoneNumber: I18n.get("Phone number required"),
            phoneNumberValid: false,
          });
        } else if (value.length > 15) {
          // TODO: check if this is the correct length for phone numbers
          setFormErrors({
            ...formErrors,
            phoneNumber: I18n.get(
              "Phone number don't match the expected format: too long"
            ),
            phoneNumberValid: false,
          });
        }
        //  else if (phoneNumberRegEx.test(value)) {
        //   setFormErrors({
        //     ...formErrors,
        //     phoneNumber: I18n.get(
        //       "Phone number don't match the expected format: invalid characters"
        //     ),
        //     phoneNumberValid: false,
        //   });
        // }
        else {
          setFormErrors({
            ...formErrors,
            phoneNumber: "",
            phoneNumberValid: true,
          });
        }
        break;

      case "countryCodePhoneNumber":
        fieldsUpdated.current.countryCodePhoneNumber = true;

        if (!value) {
          setFormErrors({
            ...formErrors,
            countryCodePhoneNumber: I18n.get("Country code is required"),
            countryCodePhoneNumberValid: false,
          });
        } else {
          setFormErrors({
            ...formErrors,
            countryCodePhoneNumber: "",
            countryCodePhoneNumberValid: true,
          });
        }
        break;

      case "alternativePhone":
        fieldsUpdated.current.alternativePhone = true;

        if (value.length > 15) {
          setFormErrors({
            ...formErrors,
            alternativePhone: I18n.get(
              "Phone number don't match the expected format: too long"
            ),
            alternativePhoneValid: false,
          });
        } else if (value && !countryCodeAlternativePhoneNumber) {
          setFormErrors({
            ...formErrors,
            alternativePhone: I18n.get("Country code required"),
            alternativePhoneValid: false,
          });
        } else if (countryCodeAlternativePhoneNumber && !value) {
          setFormErrors({
            ...formErrors,
            alternativePhone: I18n.get("Field cannot be empty"),
            alternativePhoneValid: false,
          });
        } else {
          setFormErrors({
            ...formErrors,
            alternativePhone: "",
            alternativePhoneValid: true,
          });
        }
        break;

      case "countryCodeAlternativePhoneNumber":
        fieldsUpdated.current.countryCodeAlternativePhoneNumber = true;

        if (alternativePhone && !value) {
          setFormErrors({
            ...formErrors,
            countryCodeAlternativePhoneNumber: I18n.get(
              "Country code is required"
            ),
            countryCodeAlternativePhoneNumberValid: false,
          });
        } else {
          setFormErrors({
            ...formErrors,
            countryCodeAlternativePhoneNumber: "",
            countryCodeAlternativePhoneNumberValid: true,
          });
        }
        break;

      case "specialities":
        fieldsUpdated.current.specialities = true;

        if (thirdParameter?.length === 0) {
          setFormErrors({
            ...formErrors,
            specialities: I18n.get("Speciality required"),
            specialitiesValid: false,
          });
        } else {
          setFormErrors({
            ...formErrors,
            specialities: "",
            specialitiesValid: true,
          });
        }
        break;

      case "languages":
        fieldsUpdated.current.languages = true;

        if (thirdParameter?.length === 0) {
          setFormErrors({
            ...formErrors,
            languages: I18n.get("Language required"),
            languagesValid: false,
          });
        } else {
          setFormErrors({
            ...formErrors,
            languages: "",
            languagesValid: true,
          });
        }
        break;

      case "preferredLanguage":
        fieldsUpdated.current.preferredLanguage = true;

        if (!value) {
          setFormErrors({
            ...formErrors,
            preferredLanguage: I18n.get("Preferred language required"),
            preferredLanguageValid: false,
          });
        } else {
          setFormErrors({
            ...formErrors,
            preferredLanguage: "",
            preferredLanguageValid: true,
          });
        }
        break;

      case "professionalNumber":
        fieldsUpdated.current.professionalNumber = true;

        // if (REGEX) {
        //   setFormErrors({
        //     ...formErrors,
        //     professionalNumber: I18n.get("Please enter a valid professional number"),
        //     professionalNumberValid: false,
        //   });
        // }

        // else {
        setFormErrors({
          ...formErrors,
          professionalNumber: "",
          professionalNumberValid: true,
        });
        // }
        break;

      case "insurance":
        fieldsUpdated.current.insurance = true;

        // if (REGEX) {
        //   setFormErrors({
        //     ...formErrors,
        //     insurance: I18n.get("Please enter a valid professional number"),
        //     insuranceValid: false,
        //   });
        // }

        // else {
        setFormErrors({
          ...formErrors,
          insurance: "",
          insuranceValid: true,
        });
        // }
        break;

      case "resume":
        fieldsUpdated.current.resume = true;

        // if (REGEX) {
        //   setFormErrors({
        //     ...formErrors,
        //     resume: I18n.get("Please enter a valid professional number"),
        //     resumeValid: false,
        //   });
        // }
        if (value.length > 2000) {
          setFormErrors({
            ...formErrors,
            resume: I18n.get("Maximum length reached"),
            resumeValid: false,
          });
        } else {
          setFormErrors({
            ...formErrors,
            resume: "",
            resumeValid: true,
          });
        }
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    getLanguages().then((res) => {
      if (res?.data?.getSettings?.values) {
        setLangChoices(JSON.parse(res.data.getSettings.values).languages);
      }
    });

    getSpecialities().then((res) => {
      if (res?.data?.getSettings?.values)
        setSpecialitiyChoices(
          JSON.parse(res.data.getSettings.values).specialties
        );
    });
  }, []);

  const map = useRef<any>(null);
  const worldZoom = 0;

  async function initializeMap() {
    // if (!maplibregl.supported()) {
    //   alert(
    //     I18n.get(
    //       "Your browser does not support MapLibre GL, please use a browser with WebGL support."
    //     )
    //   );
    // }

    const map = await createMap({
      container: "map", // An HTML Element or HTML element ID to render the map in https://maplibre.org/maplibre-gl-js-docs/api/map/
      zoom: selectedLocationData?.geometry?.point.length ? 15 : worldZoom, // if location is NOT set, display world map
    });

    // https://docs.mapbox.com/mapbox-gl-js/api/handlers/
    // Disable user interaction with the map
    //  Desktop
    map.dragRotate.disable();
    // map.dragPan.disable();
    map.keyboard.disable();
    // Mobile
    map.touchZoomRotate.disable();
    return map;
  }

  useEffect(() => {
    const initMap = async () => await initializeMap();
    initMap().then((m) => {
      map.current = m;
      setInitPostion(true);
    });

    return () => {
      map.current.remove();
    };
  }, []);

  useEffect(() => {
    // WORKAROUND to init map position
    // console.log("!! reset map", initPosition);

    // if (initPosition && userAlreadyExist) {
    if (initPosition) {
      setInitPostion(false);

      // Set markers if user already exist (choosen its address)
      if (selectedLocationData?.geometry?.point.length) {
        handleFlyClick(selectedLocationData.geometry.point);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [initPosition, userAlreadyExist]);
  }, [initPosition]);

  const handleFlyClick = (points: number[]) => {
    const [lng, lat] = points;

    if (mapMarker) {
      mapMarker.setLngLat([lng, lat]);
    } else {
      let newMarker = new maplibregl.Marker({
        color: "rgb(31 192 180)",
        draggable: false,
      })
        .setLngLat([lng, lat])
        .addTo(map.current);
      setMapMarker(newMarker);
    }

    if (map.current && lng !== 0 && lat !== 0) {
      map.current.flyTo({
        zoom: 18, // if location is set, zoom on location
        center: [lng, lat],
        animate: false, // Disable animation
      });
    }
  };

  const handlePreferredLanguageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const preferredLang = e.target.value;
    setPreferredLanguage(preferredLang);
    if (Boolean(userId)) {
      updateCurrentDoctor({
        input: { id: userId, preferred_language: preferredLang },
      }).catch((err: any) => console.log("!! error updating language", err));
    }
  };

  const handleRequiredFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case "phoneNumber":
        setPhoneNumber(e.target.value);
        break;
      default:
        break;
    }

    setFieldsError({ ...fieldsError, [name]: !Boolean(value) });
  };

  const handleRequiredSelectFieldChange = (name: string, value: String[]) => {
    setFieldsError({ ...fieldsError, [name]: !Boolean(value.length) });
  };

  return (
    <>
      <FormControl>
        <LocationSearchInput
          selectedLocationData={selectedLocationData}
          handleFlyClick={handleFlyClick}
          setSelectedLocationData={setSelectedLocationData}
          isSubmitted={isSubmitted}
        />
        <Button
          variant="contained"
          sx={{
            display: isMobile ? "inline" : "none",
            color: "white",
            mb: mapVisible ? 0 : 5,
          }}
          onClick={() => {
            setMapVisible(!mapVisible);
          }}
        >
          {mapVisible ? I18n.get("Hide map") : I18n.get("Display map")}
        </Button>
        <Box
          sx={{
            display: mapVisible ? "inline" : "none",
            mt: mapVisible ? 0 : 5,
          }}
        >
          <div className="map" id="map"></div>
        </Box>
        <TextField
          onChange={(e) => {
            setAdditionalAddressInfos(e.target.value);
            handleOnBlur(e, "additionalAddressInfos");
          }}
          value={additionalAddressInfos}
          label={I18n.get("Additional address informations")}
          placeholder={I18n.get("Additional address informations")}
          helperText={
            formErrors.additionalAddressInfosValid
              ? " "
              : formErrors.additionalAddressInfos
          }
          onBlur={(e) => handleOnBlur(e, "additionalAddressInfos")}
          error={
            fieldsUpdated.current.additionalAddressInfos &&
            formErrors.additionalAddressInfos !== "" &&
            formErrors.additionalAddressInfos !== " "
              ? true
              : false
          }
        />

        <Flex className="mobileColumn">
          <CountrySelector
            countryCode={countryCodePhoneNumber}
            setCountryCode={setCountryCodePhoneNumber}
            fieldRequired={true}
            phoneNumber={phoneNumber}
            isSubmitted={isSubmitted}
          />
          <TextField
            className="halfProfileFormField phoneNumber"
            required
            id="phoneNumber"
            name="phoneNumber"
            label={I18n.get("Phone number")}
            onChange={handleRequiredFieldChange}
            value={phoneNumber}
            placeholder={I18n.get("Phone number")}
            error={fieldsError.phoneNumber}
          />
        </Flex>

        <Flex className="mobileColumn">
          <CountrySelector
            countryCode={countryCodeAlternativePhoneNumber}
            setCountryCode={setCountryCodeAlternativePhoneNumber}
            fieldRequired={false}
            phoneNumber={alternativePhone}
          />

          <TextField
            className="halfProfileFormField"
            id="alternativePhoneNumber"
            label={I18n.get("Alternative phone number")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAlternativePhone(e.target.value);
              handleOnBlur(e, "alternativePhone");
            }}
            // autoComplete={"off"}
            placeholder={I18n.get("Alternative phone")}
            value={alternativePhone}
            helperText={
              formErrors.alternativePhoneValid
                ? " "
                : formErrors.alternativePhone
            }
            onBlur={(e) => handleOnBlur(e, "alternativePhone")}
            error={
              fieldsUpdated.current.alternativePhone &&
              formErrors.alternativePhone !== "" &&
              formErrors.alternativePhone !== " "
                ? true
                : false
            }
          />
        </Flex>

        <Stack spacing={3} sx={{ width: "100%" }}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            multiple
            disableCloseOnSelect={true}
            id="tags-standard"
            noOptionsText={I18n.get("No options")}
            options={specialitiyChoices.filter(
              (i) => !specialities.includes(i)
            )}
            value={specialities}
            onChange={(_event, newSpeciality) => {
              setSpecialities(newSpeciality);
              // handleOnBlur(_event, "specialities", newSpeciality);
              handleRequiredSelectFieldChange("specialities", newSpeciality);
            }}
            getOptionLabel={(option) => I18n.get(option)}
            renderInput={(params) => (
              <TextField
                required={true}
                {...params}
                label={I18n.get("Speciality")}
                placeholder={
                  params ? " " : I18n.get("Type in or select your specialty")
                }
                helperText={
                  fieldsError.specialities
                    ? I18n.get("Speciality required")
                    : undefined
                }
                error={fieldsError.specialities}
              />
            )}
          />
        </Stack>

        <Stack spacing={3} sx={{ width: "100%", my: 2 }}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            multiple
            disableCloseOnSelect={true}
            id="language"
            options={langChoices.filter((i) => !languages.includes(i))}
            value={languages}
            noOptionsText={I18n.get("No options")}
            onChange={(_event, newLanguages) => {
              setLanguages(newLanguages);

              // handleOnBlur(_event, "languages", newLanguages);
              handleRequiredSelectFieldChange("languages", newLanguages);
            }}
            getOptionLabel={(option) => I18n.get(option)}
            renderInput={(params) => (
              <TextField
                label={I18n.get("Language(s)")}
                required={true}
                {...params}
                placeholder={
                  params
                    ? " "
                    : I18n.get("Type in or select your spoken language(s)")
                }
                helperText={
                  fieldsError.languages
                    ? I18n.get("Language required")
                    : undefined
                }
                error={fieldsError.languages}
              />
            )}
          />
        </Stack>

        <FormControl>
          <InputLabel id="preferredCommunicationLanguageLabel">
            {I18n.get("Preferred Communication Language")}
            <Tooltip
              title={I18n.get(
                "We will use this language to communicate with you."
              )}
              placement="top"
              enterTouchDelay={0}
              leaveTouchDelay={-1}
              sx={{ zIndex: 3, backgroundColor: "grey", color: "grey" }}
            >
              <Button
                sx={{
                  backgroundColor: "grey",
                  color: "grey",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <HelpIcon sx={{ backgroundColor: "grey", color: "grey" }} />
              </Button>
            </Tooltip>
          </InputLabel>

          <Select
            placeholder={I18n.get("Preferred Communication Language")}
            labelId="preferredCommunicationLanguageId"
            id="preferredCommunicationLanguage"
            value={preferredLanguage ? preferredLanguage : ""}
            label="Preferred Communication Language"
            onChange={(e: any) => {
              setPreferredLanguage(e.target.value);
              handleOnBlur(e, "preferredLanguage");
            }}
            onBlur={(e) => handleOnBlur(e, "preferredLanguage")}
            error={
              fieldsUpdated.current.preferredLanguage &&
              formErrors.preferredLanguage !== "" &&
              formErrors.preferredLanguage !== " "
                ? true
                : false
            }
          >
            <MenuItem value="fr">{I18n.get("Français")}</MenuItem>
            <MenuItem value="en">{I18n.get("English")}</MenuItem>
            <MenuItem value="es">{I18n.get("Español")}</MenuItem>
          </Select>
          <FormHelperText
            id="preferredLanguage-helper-text"
            className="errorText"
          >
            {fieldsUpdated.current.preferredLanguage &&
            formErrors.preferredLanguage
              ? formErrors.preferredLanguage
              : " "}
          </FormHelperText>
        </FormControl>

        <Flex className="mobileColumn">
          <TextField
            className="halfProfileFormField"
            id="professionalNumber"
            label={I18n.get("Professional number")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProfessionalNumber(e.target.value);
              handleOnBlur(e, "professionalNumber");
            }}
            placeholder={I18n.get("Professional number")}
            value={professionalNumber}
            helperText={
              formErrors.professionalNumberValid
                ? " "
                : formErrors.professionalNumber
            }
            onBlur={(e) => handleOnBlur(e, "professionalNumber")}
            error={
              fieldsUpdated.current.professionalNumber &&
              formErrors.professionalNumber !== "" &&
              formErrors.professionalNumber !== " "
                ? true
                : false
            }
          />

          <TextField
            className="halfProfileFormField"
            id="insuranceNumber"
            label={I18n.get("Insurance number")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInsurance(e.target.value);
              handleOnBlur(e, "insurance");
            }}
            placeholder={I18n.get("Insurance number")}
            value={insurance}
            helperText={formErrors.insuranceValid ? " " : formErrors.insurance}
            onBlur={(e) => handleOnBlur(e, "insurance")}
            error={
              fieldsUpdated.current.insurance &&
              formErrors.insurance !== "" &&
              formErrors.insurance !== " "
                ? true
                : false
            }
          />
        </Flex>
        <TextField
          placeholder={I18n.get("")}
          multiline
          rows={4}
          id="resume"
          label={I18n.get("Presentation and qualifications")}
          value={resume}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResume(e.target.value);
            handleOnBlur(e, "resume");
          }}
          helperText={formErrors.resumeValid ? " " : formErrors.resume}
          onBlur={(e) => handleOnBlur(e, "resume")}
          error={
            fieldsUpdated.current.resume &&
            formErrors.resume !== "" &&
            formErrors.resume !== " "
              ? true
              : false
          }
        />

        <Text>
          {I18n.get("Character count:")} {`${resume.length}`}/2000
        </Text>
      </FormControl>
    </>
  );
};

export default EditProfileProfessionalForm;
