import { Divider, Text } from "@aws-amplify/ui-react";
import { Logout } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import {
  FormControl,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  styled,
  Select,
} from "@mui/material";

import { SelectChangeEvent } from "@mui/material/Select";
import { Auth, I18n } from "aws-amplify";

import * as React from "react";
import { useState } from "react";

import { useGlobalContext } from "../commons/ContextProvider/ContextProvider";

import UserAvatar from "../commons/UserAvatar";
import ChangeLangAlert from "./Header/ChangeLangAlert";
import "./Translate.css";

const StyledSelect = styled(Select)({
  color: "white",
  "& svg": {
    color: "white",
  },
});

type Props = {
  handleChangeLanguage: (lang: string) => void;
  handleGoToProfile: () => void;
  user?: any;
  setUser: any;
  lang: string;
};

const Translate = (props: Props) => {
  const { handleChangeLanguage, handleGoToProfile, lang, user } = props;
  const { setDisplaySettings } = useGlobalContext();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [choosenLanguage, setChoosenLanguage] = useState(lang);
  const { account } = useGlobalContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    await Auth.signOut();
    window.location.reload();
  };

  const handleAskChange = (e: SelectChangeEvent<unknown>) => {
    setChoosenLanguage(e.target.value as string);
    setIsConfirmOpen(true);
  };

  const handleCancelChange = () => {
    setChoosenLanguage(lang);
    setIsConfirmOpen(false);
  };
  const handleConfirmChange = () => {
    handleChangeLanguage(choosenLanguage);
    setIsConfirmOpen(false);
  };

  const handleDisplaySettings = () => {
    if (setDisplaySettings) {
      setDisplaySettings(true);
    }
  };

  return (
    <>
      <ChangeLangAlert
        isOpen={isConfirmOpen}
        handleCancel={handleCancelChange}
        handleConfirm={handleConfirmChange}
      />
      {user ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {account.lastName ? (
              <>
                <Text
                  color={"white"}
                  alignSelf={"center"}
                  className="userEmail"
                >
                  {`${account.title}${account.title ? "." : ""} ${
                    account.firstName
                  } ${account.lastName}`}
                </Text>
              </>
            ) : (
              <>
                {" "}
                {user && user.attributes && user.attributes.email && (
                  <Text
                    color={"white"}
                    alignSelf={"center"}
                    className="userEmail"
                  >
                    {user.attributes.email}
                  </Text>
                )}
              </>
            )}

            <Tooltip title={I18n.get("Account settings")}>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 1 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <UserAvatar />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* <MenuItem>
              <Avatar /> Profile
            </MenuItem> */}
            {/* <MenuItem>
              <Avatar /> My account
            </MenuItem> */}
            {/* <Divider /> */}
            {/* <MenuItem>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Add another account
            </MenuItem> */}
            {/* <MenuItem>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem> */}
            {/* <MenuItem
              onClick={() => {
                return;
              }}
            >
              <ListItemIcon>
                <PersonOutlineIcon fontSize="small" />
              </ListItemIcon>
              {I18n.get("Profile")}
            </MenuItem> */}

            <MenuItem onClick={handleGoToProfile}>
              <ListItemIcon>
                <PersonOutlineIcon fontSize="small" />
              </ListItemIcon>
              {I18n.get("Profile")}
            </MenuItem>

            <MenuItem onClick={handleDisplaySettings}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              {I18n.get("Settings")}
            </MenuItem>

            <MenuItem onClick={handleSignOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {I18n.get("Log out")}
            </MenuItem>
          </Menu>
        </>
      ) : null}

      <Divider orientation="vertical" />

      <Box className="languagePickerContainer">
        <FormControl variant="standard" sx={{ minWidth: 100, ml: 1 }}>
          <StyledSelect
            disableUnderline={true}
            value={lang}
            onChange={handleAskChange}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="fr">Français</MenuItem>
            <MenuItem value="es">Español</MenuItem>
          </StyledSelect>
        </FormControl>
      </Box>
    </>
  );
};

export default Translate;
