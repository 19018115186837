import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { I18n } from "aws-amplify";
import { FC } from "react";

interface ChangeLangAlertProps {
  isOpen: boolean;
  handleConfirm(): void;
  handleCancel(): void;
}

const ChangeLangAlert: FC<ChangeLangAlertProps> = (props) => {
  const { isOpen, handleConfirm, handleCancel } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="alert-change-lang-title"
      aria-describedby="alert-change-lang-description"
    >
      <DialogTitle id="alert-change-lang-title">
        <Typography variant="h5">
          {I18n.get("Are you sure you want to change language?")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-change-lang-description">
          {I18n.get(
            "The data entered that has not been saved will be lost after the language change."
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="contained" color="inherit">
          {" "}
          {I18n.get("Cancel")}
        </Button>
        <Button
          onClick={handleConfirm}
          autoFocus
          variant="contained"
          sx={{ backgroundColor: "rgb(31 192 180)", color: "white" }}
          // color="success"
        >
          {I18n.get("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeLangAlert;
