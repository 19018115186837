import { Link } from "@aws-amplify/ui-react";
import Translate from "../Translate";

import { ReactComponent as WhiteLogo } from "../../assets/bakup-logo-white.svg";

import "./Header.css";
import { Box } from "@mui/material";
import MobileDrawer from "./MobileDrawer";
interface IProps {
  handleChangeLanguage: (lang: string) => void;
  handleGoToProfile: () => void;
  setCurrentPage: any;
  CurrentPageEnum: any;
  currentPage: string;
  user: any;
  setUser: any;
  lang: string;
}

const Header = (props: IProps) => {
  const {
    handleChangeLanguage,
    handleGoToProfile,
    setCurrentPage,
    CurrentPageEnum,
    user,
    setUser,
    lang,
  } = props;

  return (
    <nav
      className="header"
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        height: 70,
        backgroundColor: "#1fc0b4",
        zIndex: -1,
        paddingRight: 20,
        paddingLeft: 20,
        alignItems: "center",
        maxWidth: "100%",
      }}
    >
      {!user ? (
        <Link href="/" isExternal={false} className="credentialsLogoContainer">
          <WhiteLogo className="whiteLogo"></WhiteLogo>
        </Link>
      ) : (
        <>
          <MobileDrawer handleGoToProfile={handleGoToProfile} />
          <Link href="/" isExternal={false} className="logoContainer">
            <WhiteLogo className="whiteLogo" />
          </Link>
        </>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Translate
          lang={lang}
          handleChangeLanguage={handleChangeLanguage}
          user={user}
          setUser={setUser}
          handleGoToProfile={handleGoToProfile}
        />
      </Box>
    </nav>
  );
};
export default Header;
