interface PhoneFormat {
  country: any;
  number: string;
}

// country is like: {code: 'AE', label: 'United Arab Emirates', phone: '971'}
export const getPhoneIfOptional = (
  country: any,
  phoneNumber: string
): PhoneFormat => {
  if (
    country.code &&
    Boolean(country.code.trim()) &&
    phoneNumber.trim().length
  ) {
    return { country, number: phoneNumber };
  }
  return { country: { code: "", phone: "", label: "" }, number: "" };
};
