import { AmplifyProvider, Theme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, I18n } from "aws-amplify";
import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect, useState } from "react";
import { AuthStrings } from "./utils/amplifyUITranslations";
import "./App.css";
import awsconfig from "./aws-exports";
import Header from "./components/Header/Header";
import MainApp from "./components/MainApp";
import { dict } from "./utils/i18n-dict";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// const isLocalhost = Boolean(
//   window.location.hostname === "localhost" ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === "[::1]" ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// );

// // Assuming you have two redirect URIs, and the first is for localhost and second is for production
// const [localRedirectSignIn, devRedirectSignIn] =
//   awsconfig.oauth.redirectSignIn.split(",");

// const [localRedirectSignOut, devRedirectSignOut] =
//   awsconfig.oauth.redirectSignOut.split(",");

// const updatedAwsConfig = {
//   ...awsconfig,
//   oauth: {
//     ...awsconfig.oauth,
//     redirectSignIn: isLocalhost ? localRedirectSignIn : devRedirectSignIn,
//     redirectSignOut: isLocalhost ? localRedirectSignOut : devRedirectSignOut,
//   },
// };

// Amplify.configure(updatedAwsConfig);

Amplify.configure(awsconfig);
I18n.putVocabularies(dict);

function App() {
  // TODO: Store user preferred language in localStorage/cookie to not override his chosen language each time the app reload
  const supportedLanguages = ["en", "fr", "es"];
  const browserLanguage = navigator.language.split("-")[0];

  const [user, setUser] = useState<any>();
  const userLanguage = user?.attributes.locale;
  const [lang, setLang] = useState(
    userLanguage || supportedLanguages.includes(browserLanguage)
      ? browserLanguage
      : "en"
  );
  // TODO IDEA: We detected that your preferred browser language is French, would like to switch to French?

  I18n.setLanguage(lang);
  I18n.putVocabulariesForLanguage("fr-FR", AuthStrings);

  enum CurrentPageEnum {
    PROFILE = "profile",
    ADVANTAGE = "advantage",
  }

  const handleGoToProfile = () => {
    setCurrentPage(CurrentPageEnum.PROFILE);
  };

  const [currentPage, setCurrentPage] = useState(CurrentPageEnum.PROFILE);

  const handleChangeLanguage = (lang: string) => {
    setLang(lang);
    localStorage.setItem("lang", lang);
  };

  const theme: Theme = {
    //https://ui.docs.amplify.aws/theming
    name: "my-theme",

    tokens: {
      colors: {
        primaryColor: { value: "#1fc0b4" },
        secondaryColor: { value: "#61dafb" },

        font: {},
      },
    },
  };

  const muiTheme = createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          // disableRipple: true, // disables MUI ripple effect on buttons
        },
      },
    },
    palette: {
      primary: {
        main: "#1fc0b4",
      },
      secondary: {
        main: "#61dafb",
      },
      error: {
        main: "#BF2F28",
      },
    },
    typography: {
      h6: {
        // mimic MuiFormLabel
        color: "rgba(0, 0, 0, 0.6)",
        fontFamily: "Arial",

        fontSize: "1rem",
        fontWeight: 400,
        letterSpacing: "0.00938em",
        lineHeight: "1.4375em",
      },
    },
  });

  // Get lang from locale storage
  useEffect(() => {
    const lng = localStorage.getItem("lang");
    if (lng) {
      setLang(lng);
    }
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <AmplifyProvider theme={theme}>
        <Header
          user={user}
          handleChangeLanguage={handleChangeLanguage}
          setUser={setUser}
          lang={lang}
          currentPage={currentPage}
          handleGoToProfile={handleGoToProfile}
          setCurrentPage={setCurrentPage}
          CurrentPageEnum={CurrentPageEnum}
        />

        <MainApp
          lang={lang}
          setUser={setUser}
          setLang={setLang}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          handleGoToProfile={handleGoToProfile}
          CurrentPageEnum={CurrentPageEnum}
        />
      </AmplifyProvider>
    </ThemeProvider>
  );
}

export default App;
