import { I18n } from "aws-amplify";
import { Box, Typography } from "@mui/material";

import mobileAppScreen from "./assets/mobile-app-fixed.png";
import locationMarker from "./assets/location-marker.png";

const IntroductionTexts = () => {
  return (
    <>
      <Box className={"introductionContainer"}>
        <Box className={"introductionTextContainer"}>
          <Typography variant="body1" className={"introductionTextOne"} mb={2}>
            {I18n.get(
              "Nous sommes actuellement en train de finaliser la prise de rendez-vous en ligne, la téléconsultation ainsi que beaucoup d'autres fonctionnalités pour vous permettre de prendre en charge vos patients le plus efficacement possible"
            )}
          </Typography>

          <Typography variant="body1" className={"introductionTextTwo"} mb={3}>
            {I18n.get(
              "Lors de la sortie de l'application, cette page sera votre tableau de bord !"
            )}
          </Typography>
          <Typography
            variant="body1"
            className={"introductionTextThree"}
            mb={5}
          >
            {I18n.get(
              "Depuis cette page et le centre de notification, vous pourrez donc suivre le développement de la solution et vous serez avertis de la sortie des nouvelles fonctionnalités."
            )}
          </Typography>
          <Box
            component="img"
            alt=""
            src={mobileAppScreen}
            className={"mobileAppScreen"}
          />
        </Box>
        <Box
          component="img"
          alt=""
          src={mobileAppScreen}
          className={"desktopAppScreen"}
        />
      </Box>
      <Box className="mobileintroContainerDecorationTwo">
        <Box className={"introductionTextFourContainer"}>
          <Box
            component="img"
            alt=""
            src={locationMarker}
            className={"locationMarker"}
          />
          <Box className={"introductionTextFour"}>
            <Typography variant="body1">
              {I18n.get(
                "C'est également depuis cette page que vous verrez les demandes de rendez-vous auxquels vous pourrez répondre pour prendre en charge vos patients."
              )}
            </Typography>
            <br />

            <Typography variant="body1">
              {I18n.get(
                "Nous vous invitons donc à toujours veiller à ce que les informations de votre profil soient constamment mise à jour comme le mentionnent les CGU."
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IntroductionTexts;
