import { I18n } from "aws-amplify";
import { Box, Typography } from "@mui/material";

import { useGlobalContext } from "../../commons/ContextProvider/ContextProvider";

const IntroductionHeader = () => {
  const { account } = useGlobalContext();

  return (
    <Box className={"introContainerDecoration"}>
      <Box className={"mobileintroContainerDecoration"}>
        <Typography
          variant="h1"
          mt={5}
          className={"headerText decorationAnchor4"}
        >
          {I18n.get("Cher / Chère").toUpperCase()}{" "}
          <span>{account.firstName.toLocaleUpperCase()}</span>{" "}
          {I18n.get(
            "vous venez de finaliser la première partie de votre inscription"
          ).toUpperCase()}
        </Typography>

        <Typography
          variant="body1"
          mt={2}
          mb={5}
          className={"headerText introTextDivider title"}
        >
          {I18n.get(
            "L'équipe Bakup vous remercie de votre confiance !"
          ).toUpperCase()}
        </Typography>
      </Box>
    </Box>
  );
};

export default IntroductionHeader;
