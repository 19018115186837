import { I18n } from "aws-amplify";
import { Box, Typography } from "@mui/material";

import map from "./assets/map.png";

const WorldPresence = () => {
  return (
    <Box className="worldCoverageTextMapContainer">
      <Box component="img" alt="" src={map} className="mapImage" />

      <Typography variant="h2" mt={2} className="worldCoverageText">
        {I18n.get(
          `Bakup est actuellement présent dans 18 destinations et continue son développement`
        ).toUpperCase()}
      </Typography>
    </Box>
  );
};

export default WorldPresence;
