import AddPhotoIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { I18n, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../../commons/ContextProvider/ContextProvider";
import { getAccountOfficeImageFolder } from "../../../../utils/account";
import { getFileNameByAWSkey } from "../../../../utils/file";

const StyledIconButton = styled(IconButton)<
  IconButtonProps & { component?: string } // Explicitely add "component" props to prevent TS error
>(({ color, theme }) => ({
  color: "white",
  backgroundColor: color === "error" ? theme.palette.error.main : "#1fc0b4",
  "&:hover": {
    backgroundColor: color === "error" ? theme.palette.error.dark : "#1dada3",
  },
}));

const Input = styled("input")({
  display: "none",
});

const FILE_SIZE_MB = 10;
const MAX_ALLOWED_FILE_SIZE = FILE_SIZE_MB * 1024 * 1024;
const MAX_OFFICE_IMAGES_NB = 4;

interface ProfileImageItem {
  key: string;
  url: string;
}

const OfficeImages = () => {
  const [officeImages, setOfficeImages] = useState<ProfileImageItem[]>([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { account } = useGlobalContext();

  const officeImagesFolder = getAccountOfficeImageFolder(account.id);

  const allImagesUploaded = officeImages.length >= MAX_OFFICE_IMAGES_NB;
  const imageListHeight = officeImages.length > 2 ? 700 : 350;

  const fetchOfficeImagesUrl = async () => {
    try {
      setLoading(true);
      const results = await Storage.list(`${officeImagesFolder}/`, {
        level: "private",
      });
      const currentImages: ProfileImageItem[] = [];
      if (results) {
        for (const r of results) {
          if (r.key) {
            try {
              const currentUrl = await Storage.get(r.key, {
                level: "private",
              });
              currentImages.push({ key: r.key, url: currentUrl });
            } catch (err) {
              console.log("!! error while getting file: ", r.key);
            }
          }
        }
      }
      setOfficeImages(currentImages);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOfficeImagesUrl();
  }, []);

  const handleUploadFiles = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (allImagesUploaded) {
      return;
    }
    if (event?.target?.files) {
      setLoading(true);
      const allImages = event.target.files;
      const additionnalImages: ProfileImageItem[] = [];
      const filteredImages: File[] = [];

      const allowedLength =
        allImages.length < MAX_OFFICE_IMAGES_NB
          ? allImages.length
          : MAX_OFFICE_IMAGES_NB;

      for (let i = 0; i < allowedLength; i++) {
        if (allImages[i].size <= MAX_ALLOWED_FILE_SIZE) {
          // Prevent duplicate
          if (
            !officeImages.find(
              (img) => getFileNameByAWSkey(img.key) === allImages[i].name
            )
          ) {
            filteredImages.push(allImages[i]);
          }
        }
      }

      const additionnalNb = Math.abs(
        officeImages.length - filteredImages.length
      );

      const imagesToAdd =
        officeImages.length + filteredImages.length > MAX_OFFICE_IMAGES_NB
          ? filteredImages.slice(0, additionnalNb)
          : filteredImages;
      try {
        for (const img of imagesToAdd) {
          const currentKey = `${officeImagesFolder}/${img.name}`;
          await Storage.put(currentKey, img, { level: "private" });
          const currentUrl = await Storage.get(currentKey, {
            level: "private",
          });

          // Prevent duplicate
          // if (!officeImages.find((img) => img.key === currentKey)) {
          additionnalImages.push({ key: currentKey, url: currentUrl });
          // }
        }
        setOfficeImages([...additionnalImages, ...officeImages]);
      } catch (err) {
        console.log("!! error uploading file", err);
      } finally {
        setLoading(false);
      }
    }
    event.target.value = "";
  };

  const handleDeleteImage = (keyToRemove: string) => async () => {
    try {
      setLoading(true);
      await Storage.remove(keyToRemove, { level: "private" });
      setOfficeImages([...officeImages.filter((i) => i.key !== keyToRemove)]);
    } catch (err) {
      console.log("!! error deleting file", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={1}
      >
        <Typography variant="h6">{I18n.get("Office pictures")}</Typography>
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <label htmlFor="upload-office-img">
            <Input
              accept="image/*"
              id="upload-office-img"
              type="file"
              multiple={true}
              onChange={handleUploadFiles}
              disabled={loading || allImagesUploaded}
            />
            <StyledIconButton
              component="span"
              aria-label={`Add office pictures`}
              disabled={loading || allImagesUploaded}
            >
              <AddPhotoIcon />
            </StyledIconButton>
          </label>
        )}
      </Box>
      {!loading && !Boolean(officeImages.length) ? (
        <Alert severity="info">
          <Typography variant="body1">
            {I18n.get("Office pictures not set")}
          </Typography>
          <Typography variant="subtitle2">
            {I18n.get("* Max picture size (MB):")} {FILE_SIZE_MB}
          </Typography>
        </Alert>
      ) : (
        <>
          {officeImages.length ? (
            <Box display="flex" flexWrap="wrap">
              {officeImages.map((img) => (
                <Box
                  key={img.key}
                  width={isMobile ? "100%" : "50%"}
                  p={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box>
                    <img
                      src={img.url}
                      // alt={img.url}
                      // alt={img.url}
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="caption">
                      {getFileNameByAWSkey(img.key)}
                    </Typography>
                    <Box display="flex">
                      {/* <IconButton
                          aria-label={`change ${img.key}`}
                          size="small"
                          color="warning"
                          disabled={loading}
                        >
                          <EditIcon />
                        </IconButton> */}
                      <IconButton
                        aria-label={`delete ${img.key}`}
                        size="small"
                        // color="error"
                        color="error"
                        disabled={loading}
                        onClick={handleDeleteImage(img.key)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          ) : null}
        </>
      )}
    </>
  );
};

export default OfficeImages;
