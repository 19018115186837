import { CognitoUserAmplify } from "@aws-amplify/ui";
import { Auth, I18n } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../commons/ContextProvider/ContextProvider";
import Profile from "../Profile";
import { getCurrentDoctor } from "../Profile/consumers";

interface CheckUserProps {
  user: CognitoUserAmplify;
  lang: string;

  currentPage: string;
  setCurrentPage: (page: string) => void;
  CurrentPageEnum: any;
  handleGoToProfile: () => void;
}

const CheckUser: React.FC<CheckUserProps> = (props) => {
  const {
    user,
    lang,
    currentPage,
    setCurrentPage,
    CurrentPageEnum,
    handleGoToProfile,
  } = props;
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(true);
  const { account, setAccount } = useGlobalContext();

  useEffect(() => {
    const userAtt = user as any; // Workaround to get user attributes directly
    if (userAtt?.attributes) {
      const { sub, email } = userAtt.attributes;
      getCurrentDoctor({
        id: user.username,
      })
        .then((res) => {
          if (res?.data?.getDoctors) {
            setUserId(res?.data?.getDoctors?.id);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("!! error fetching user", error);
        });

      if (setAccount) {
        setAccount({ ...account, id: sub, email });
      }
    } else {
      // userAtt is undefined after a created user from Amplify console changed his/her password
      Auth.currentAuthenticatedUser().then((userData) => {
        if (userData.attributes.sub) {
          window.location.reload();
        }
      });
    }
  }, []);

  // User creation is done via lambda postConfirmation
  // Create user if not exist yet into DB
  // if (!loading && !Boolean(userId)) {
  //   console.log("!! not saved yet into DB");

  //   const input = {
  //     id: sub,
  //     profile_status: "",
  //     last_name: "",
  //     languages: [],
  //     gender: "",
  //     email,
  //     first_name: "",
  //     specialties: [],
  //     preferred_language: "",
  //   };

  //   createCurrentDoctor({
  //     input,
  //   })
  //     .then((data: any) => {
  //       // To improve, use history.push() while routing is set
  //       window.location.reload();
  //     })
  //     .catch((err) => console.log("!! Error initialize doctor", err));
  // }

  return (
    <>
      {Boolean(userId) ? (
        <Profile
          user={user}
          lang={lang}
          handleGoToProfile={handleGoToProfile}
          setCurrentPage={setCurrentPage}
          CurrentPageEnum={CurrentPageEnum}
          currentPage={currentPage}
        />
      ) : (
        <div
          style={{
            paddingTop: "5%",
            margin: "auto",
            textAlign: "center",
            color: "grey",
          }}
        >
          {I18n.get("Initialization...")}
        </div>
      )}
    </>
  );
};

export default CheckUser;
