import React, { useEffect } from "react";
import { I18n } from "aws-amplify";
import { Box, Button } from "@mui/material";

import "./Homepage.css";

import {
  Advantages,
  IntroductionHeader,
  IntroductionTexts,
  CustomerThankYou,
  Visibility,
  WorldPresence,
} from "../../components/Advantage";
interface HomepageProps {
  handleGoToProfile(): void;
}

const Homepage: React.FC<HomepageProps> = (props) => {
  const { handleGoToProfile } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={"advantagesPageContainer"}>
      {/* <Button
        sx={{ width: "20%", color: "#138c83", whiteSpace: "nowrap" }}
        onClick={handleGoToProfile}
      >
        {I18n.get("See profile")}
      </Button> */}
      <IntroductionHeader />
      <IntroductionTexts />
      <WorldPresence />
      <Advantages />
      <Visibility />
      <CustomerThankYou />
    </Box>
  );
};

export default Homepage;
