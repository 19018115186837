import { I18n } from "aws-amplify";
import { Box, Typography } from "@mui/material";

const Advantages = () => {
  return (
    <Box
      mt={7}
      className={"bottomPageTextContainer mobileBottomPageDecoration"}
    >
      <Typography
        variant="h3"
        mt={2}
        className={"bottomPageText bottomPageThankYouText lighterGreen"}
      >
        {I18n.get("Merci pour votre confiance et à très vite !").toUpperCase()}
      </Typography>
      <Typography
        variant="body1"
        mt={2}
        className={"bottomPageText bakupTeamText lighterGreen"}
      >
        {I18n.get("L'équipe Bakup")}
      </Typography>
    </Box>
  );
};

export default Advantages;
