import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, graphqlOperation } from "aws-amplify";
import {
  CreateDoctorsMutation,
  CreateDoctorsMutationVariables,
  GetDoctorsQuery,
  GetDoctorsQueryVariables,
  GetSettingsQuery,
  UpdateDoctorsMutation,
  UpdateDoctorsMutationVariables,
} from "../../API";
import { createDoctors, updateDoctors } from "../../graphql/mutations";
import { getDoctors, getSettings } from "../../graphql/queries";

export const getCurrentDoctor = async (
  variables: GetDoctorsQueryVariables
): Promise<GraphQLResult<GetDoctorsQuery>> => {
  return (await API.graphql(graphqlOperation(getDoctors, variables))) as any;
};

export const createCurrentDoctor = async (
  variables: CreateDoctorsMutationVariables
): Promise<GraphQLResult<CreateDoctorsMutation>> => {
  return (await API.graphql(graphqlOperation(createDoctors, variables))) as any;
};

export const updateCurrentDoctor = async (
  variables: UpdateDoctorsMutationVariables
): Promise<GraphQLResult<UpdateDoctorsMutation>> => {
  return (await API.graphql(graphqlOperation(updateDoctors, variables))) as any;
};

export const getLanguages = async (): Promise<
  GraphQLResult<GetSettingsQuery>
> => {
  return (await API.graphql(
    graphqlOperation(getSettings, {
      type: "languages",
    })
  )) as any;
};

export const getSpecialities = async (): Promise<
  GraphQLResult<GetSettingsQuery>
> => {
  return (await API.graphql(
    graphqlOperation(getSettings, {
      type: "specialties",
    })
  )) as any;
};
