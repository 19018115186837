// v1
export const dict = {
  fr: {
    "Sign In": "Se connecter",
    "Sign in": "Se connecter",
    Logout: "Se déconnecter",
    "Create Account": "S'inscrire",
    Password: "Mot de passe",
    "Confirm Password": "Confirmation du mot de passe",
    "Forgot your password?": "Mot de passe oublié ?",
    "Family Name": "Nom",
    "Your passwords must match": "Les mots de passe doivent êtres identiques.",
    "I agree to the": "J'accepte les",
    "You must agree to the Terms & Conditions":
      "Vous devez accepter les termes et les conditions d'utilisation",
    "You must agree to the": "Vous devez accepter les",
    "I agree to": "Vous devez accepter la",
    "Privacy Policy": "Politique de confidentialité",
    "Terms & Conditions": "Conditions générales d'utilisation",
    "You must agree to the Terms & Conditions and to the Privacy Policy":
      "Vous devez accepter les Conditions d'utilisation ainsi que la Politique de confidentialité",
    "and the": "et la",
    "Send code": "Envoyer le code",
    Sending: "Envoi en cours",
    Submitting: "Envoi en cours",
    "New password": "Nouveau mot de passe",
    "Attempt limit exceeded, please try after some time.":
      "Nombre d'envoi maximum atteint, merci de réessayer plus tard.",
    "First Name": "Prénom",
    "Middle Name": "Deuxième prénom",
    "Last Name": "Nom",
    Submit: "Envoyer",
    Cancel: "Annuler",
    "Insurance Number": "Numéro d'assurance",
    Male: "Homme",
    Female: "Femme",
    Gender: "Genre",
    Other: "Autre",
    "Personal Information": "Informations personnelles",
    "Edit profile": "Édition du profil",
    "Professional Information": "Informations professionelles",
    Location: "Adresse",
    "Phone Number": "Numéro de téléphone",
    "Alternative phone": "Numéro de téléphone alternatif",
    Specialities: "Spécialités",
    Languages: "Langues",
    Resume: "Présentation",
    "Professional number": "Numéro professionnel",
    "Insurance number": "Numéro d'assurance",
    "Phone number": "Numéro de téléphone",
    "Creating Account": "Création du compte en cours...",
    "We Emailed You": "Email envoyé",
    // "Your code is on the way. To log in, enter the code we emailed to":
    //   "Votre code de confirmation à été envoyé, afin de vous connecter, entrez le code envoyé à l'email",
    // "It may take a minute to arrive.":
    //   "L'email peut prendre quelques minutes avant d'arriver.",
    "We Sent A Code": "Nous vous avons envoyé un code",
    // "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.":
    //   "Entrez le code reçu afin de vous connecter. Ce code peut prendre quelques minutes avant d'arriver.",
    "Saisissez cotre code de confirmation":
      "Saisissez votre code de confirmation",
    "Invalid verification code provided, please try again.":
      "Code de vérification invalide, merci de réessayer.",
    Confirming: "Confirmation...",
    Title: "Titre",
    "Additional address informations": "Complément d'adresse",
    "Country/Region": "Pays/Région",
    "Alternative phone number": "Numéro de téléphone alternatif",
    "Language(s)": "Langue(s)",
    "Character count:": "Nombre de caractères :",
    Speciality: "Spécialité(s)",
    "Signin in": "Connexion...",
    "Signing in": "Connexion...",
    "Cognito received the following error from Amazon SES when attempting to send email: Email address is not verified. The following identities failed the check in region EU-WEST-3: ":
      "Cognito a reçu l'erreur suivante de Amazon SES lors de l'envoi de l'email : l'adresse email n'est pas vérifiée. L'identité suivante n'a pas passé la vérification dans la région EU-WEST-3 : ",
    "Cognito received the following error from Amazon SES when attempting to send email: Email address is not verified. The following identities failed the check in region EU-WEST-3:":
      "Cognito a reçu l'erreur suivante de Amazon SES lors de l'envoi de l'email : l'adresse email n'est pas vérifiée. L'identité suivante n'a pas passé la vérification dans la région EU-WEST-3 :",
    "Incorrect username or password.":
      "Nom d'utilisateur ou mot de passe incorrect.",
    "Signin In": "Connexion...",
    "Password attempts exceeded":
      "Nombre de tentatives de connexion dépassé, veuillez réessayer plus tard.",
    "User does not exist.": "L'utilisateur n'existe pas.",
    "Log out": "Se déconnecter",
    "Account settings": "Mon Compte",
    "Successfully updated profile": "Profil mis à jour",
    "Your code is on the way. To log in, enter the code we emailed to ":
      "Votre code de confirmation à été envoyé, afin de vous connecter, entrez le code envoyé à l'email ",
    "Your code is on the way.": "Votre code de confirmation à été envoyé.",
    "You will receive a verification code": "AAAAAAAAA",
    "To log in, enter the code we emailed to":
      "Afin de vous connecter, entrez le code envoyé à l'email",
    "It may take a minute to arrive.":
      "L'email peut prendre quelques minutes avant d'arriver.",
    None: "Non spécifié",
    de: "Allemand",
    en: "Anglais",
    ar: "Arabe",
    es: "Espagnol",
    fr: "Français",
    in: "Hindi",
    it: "Italien",
    jp: "Japonais",
    md: "Mandarin",
    pt: "Portugais",
    ru: "Russe",
    tu: "Turc",
    gr: "Grec",
    Acupuncturist: "Acupuncteur",
    Engiologist: "Engiologue",
    Cardiologist: "Cardiologue",
    Physiotherapist: "Kinésithérapeute",
    "Surgeon Orthopaedic": "Chirurgien orthopédique",
    "Orthopaedic Surgeon": "Chirurgien orthopédique",
    Dentist: "Dentiste",
    ENT: "ORL",
    Dermatologist: "Dermatologue",
    Nutritionist: "Nutritionniste",
    Dietitian: "Diététicien",
    Endocrinologist: "Endocrinologue",
    Occupational: "Ergothérapeute",
    Chiropractor: "Ethiopathe",
    Gynaecologist: "Gynécologue",
    Homeopath: "Homéopathe ",
    Hypnotherapist: "Hypnothérapeute",
    Nurse: "Infirmier ",
    "General Practitioner": "Médecin Généraliste",
    Naturopath: "Naturopathe",
    Nephrologist: "Néphrologue",
    Neurologist: "Neurologue",
    "Speech Therapist": "Orthophoniste",
    Podiatrist: "Podologue",
    Psychiatrist: "Psychiatre",
    Pediatrician: "Pédiatre",
    Pedicure: "Pédicure",
    Sophrologist: "Sophrologue",
    Pharmacy: "Pharmacie",
    "Poison Control Center": "Centre anti poison",
    Laboratory: "Laboratoire",
    Pulmonologist: "Pneumologue",
    Rheumatologist: "Rhumatologue",
    Urologist: "Urologue",
    Midwife: "Sage femme",
    Clinic: "Clinique",
    Hematologist: "Hématologue",

    Ophtalmologist: "Ophtalmologue",
    "Preferred Language": "Langue préférée",
    "Initialization...": "Initialisation...",
    "https://www.mybakup.com/cgupro": "https://www.mybakup.com/cgupro?lang=fr",
    "https://www.mybakup.com/privacy-policy":
      "https://www.mybakup.com/privacy-policy?lang=fr",
    "Fields are missing or invalid.": "Champs manquants ou invalides.",
    "First name is required": "Prénom requis",
    "First name too long, maximum length is":
      "Le prénom est trop long, la longueur maximale autorisée est",
    characters: "caractères",
    "Preferred Communication Language": "Langue de communication préférée",
    "Presentation and qualifications": "Présentation et qualifications",
    "Location required": "Adresse requise",
    Settings: "Paramètres",
    "See profile": "Modifier votre profil",
    "Require at least one uppercase letter from Latin alphabet (A–Z)":
      "Au moins une lettre majuscule de l'alphabet Latin (A-Z)",
    "Require at least one lowercase letter from Latin alphabet (a–z)":
      "Au moins une lettre minuscule de l'alphabet Latin (a-z)",
    "Require at least one number": "Au moins un chiffre",
    "Require at least one nonalphanumeric character ! @ # $ % ^ & * ( ) _ + - = [ ] {} | '":
      "Au moins un caractère non alphanumérique ! @ # $ % ^ & * ( ) _ + - = [ ] {} | '",
    "Current Password": "Mot de passe actuel",
    "New Password": "Nouveau mot de passe",
    "Confirm New Password": "Confirmer le mot de passe",
    "Passwords not matches": "Les mots de passe ne correspondent pas",
    "Change password": "Changement de mot de passe",
    "Password updated successfully": "Mot de passe modifié avec succès",
    "Error while updating password":
      "Erreur lors de la mise à jour du mot de passe",
    "Profile picture not set": "Image de profil non sélectionnée",
    "Profile picture": "Photo de profil",
    "Office pictures not set": "Images du cabinet non sélectionnées",
    "Office pictures": "Photos du cabinet",
    "* Max picture size (MB):": "Taille de l'image maximale (Mo) :",
    "Last name is required": "Nom requis",
    "Country code required": "Code pays requis",
    "No options": "Aucune option",

    "Phone number required": "Numéro de téléphone requis",
    "Field cannot be empty": "Le champ ne peut pas être laissé vide",
    "Speciality required": "Spécialité requise",
    "Language required": "Langue requise",
    "We will use this language to communicate with you.":
      "Nous utiliserons cette langue pour communiquer avec vous.",
    "Display map": "Afficher la carte",
    "Hide map": "Cacher la carte",
    Back: "Retour",
    "Are you sure you want to change language?":
      "Êtes-vous sûr de vouloir changer de langue?",
    "The data entered that has not been saved will be lost after the language change.":
      "Les données saisies qui n'ont pas été sauvegardées seront perdues après le changement de la langue.",
    Confirm: "Confirmer",
    "Your profile picture": "Votre photo de profil",
    "Gender required": "Genre requis",
    "Password does not conform to policy: Password not long enough":
      "Le mot de passe n'est pas conforme : Mot de passe trop court",
    Changing: "Changement...",
    Profile: "Profil",
    Orthodontist: "Orthodontiste",
    "Dental Surgeon": "Chirurgien-Dentiste",
    Psychologist: "Psychologue",
    "Invalid email": "Email invalide",
  },
  en: {
    // 'Sign In': "Sign In",
    // 'Sign in': "Sign in",
    // 'Create Account': "Create Account",
    // 'Password': 'Password',
    // 'Confirm Password': 'Confirm Password',
    // 'Forgot your password?': 'Forgot your password?',
    // // 'Family Name': 'Last name',
    // // 'Middle Name': 'First name',
    // "Privacy Policy": "Privacy Policy",
    // "I agree to the": "I agree to the",
    // "Attempt limit exceeded, please try after some time.": "Attempt limit exceeded, please try again after some time."
    de: "German",
    en: "English",
    ar: "Arabic",
    es: "Spanish",
    fr: "French",
    in: "Hindi",
    it: "Italian",
    jp: "Japanese",
    md: "Mandarin",
    pt: "Portuguese",
    ru: "Russian",
    tu: "Turkish",
    gr: "Greek",
    "https://www.mybakup.com/cgupro": "https://www.mybakup.com/cgupro?lang=en",
    "https://www.mybakup.com/privacy-policy":
      "https://www.mybakup.com/privacy-policy?lang=en",

    "Cher / Chère": "Dear",
    "vous venez de finaliser la première partie de votre inscription":
      "YOU HAVE JUST FINALIZED THE FIRST PART OF YOUR REGISTRATION",
    "L'équipe Bakup vous remercie de votre confiance !":
      "THE BAKUP TEAM THANKS YOU FOR YOUR TRUST!",
    "Nous sommes actuellement en train de finaliser la prise de rendez-vous en ligne, la téléconsultation ainsi que beaucoup d'autres fonctionnalités pour vous permettre de prendre en charge vos patients le plus efficacement possible":
      "We are currently finalizing the online appointment booking, the teleconsultation and many other features to allow you to take care of your patients as efficiently as possible.",
    "Lors de la sortie de l'application, cette page sera votre tableau de bord !":
      "When the application is released, this page will be your dashboard!",
    "Depuis cette page et le centre de notification, vous pourrez donc suivre le développement de la solution et vous serez avertis de la sortie des nouvelles fonctionnalités.":
      "From this page and the notification center, you will be able to follow the development of the solution and you will be notified of the release of new features.",
    "C'est également depuis cette page que vous verrez les demandes de rendez-vous auxquels vous pourrez répondre pour prendre en charge vos patients.":
      "It is also from this page that you will see the appointment requests that you can respond to in order to take care of your patients.",
    "Nous vous invitons donc à toujours veiller à ce que les informations de votre profil soient constamment mise à jour comme le mentionnent les CGU.":
      "We therefore invite you to always make sure that your profile information is constantly updated as mentioned in the TOS.",
    "Bakup est actuellement présent dans 18 destinations et continue son développement":
      "BAKUP IS CURRENTLY PRESENT IN 18 DESTINATIONS AND CONTINUES TO GROW",
    "Vos avantages avec Bakup :": "Your advantages with Bakup:",
    "35 spécialités": "35 specialties",
    "référencées sur la plateforme": "referenced on the platform",
    "Gagner en visibilité auprès ": "Gain visibility with",
    "des acteurs du tourisme": "tourism actors",
    "Aider les voyageurs": "Help travelers",
    à: "to",
    "profiter sereinement de leur séjour":
      "enjoy their stay with peace of mind",
    "Améliorer l'accès aux soins": "Improve access to healthcare",
    "sur votre secteur auprès des voyageurs et":
      "to travelers in your area and",
    "de désengorger les services d'urgences":
      "to relieve the emergency services",
    "Développer ": "Develop",
    "votre réseau": "your network",
    "Intégrer une plateforme 100% gratuite": "Integrate a 100% free platform",
    "(incluant la téléconsultation à venir)":
      "(including upcoming teleconsultation)",

    "Vous êtes visible auprès des": "You are visible to",
    "voyageurs en fonction :": "travelers according to:",
    "De votre": "Your",
    géolocalisation: "geolocation",
    Des: "The",
    "tarifs pratiqués": "rates you charge",
    "De vos": "Your",
    "langues parlées": "spoken languages",
    disponibilités: "availability",
    qualifications: "qualifications",
    "Merci pour votre confiance et à très vite !":
      "Thank you for your trust and see you soon!",
    "L'équipe Bakup": "Team Bakup",

    "Vous êtes visible auprès des voyageurs en fonction de votre":
      "You are visible to travelers according to your",
    "géolocalisation, ": "geolocation, ",
    "des ": "the ",
    "tarifs pratiqués, ": "rates you charge, ",
    "de vos ": "your ",
    "langues parlées, ": "spoken languages, ",
    "disponibilités, ": "availability, ",
    "qualifications.": "qualifications.",
    "Cannot reset password for the user as there is no registered/verified email or phone_number":
      "Cannot reset password for the user as there is no registered/verified email or phone number",
    "Surgeon Orthopaedic": "Orthopaedic surgeon",
    Occupational: "Occupational therapist",
    Profile: "Profile",
  },

  es: {
    "Sign In": "Conectar",
    "Sign in": "Conectar",
    "Create Account": "Registrarse",
    Password: "Contraseña",
    "Confirm Password": "Confirmar contraseña",
    "Forgot your password?": "¿Ha olvidado su contraseña?",
    "Family Name": "Apellidos",
    "Your passwords must match": "Sus contraseñas deben coincidir.",
    "I agree to the": "Estoy de acuerdo con el",
    "You must agree to the Terms & Conditions":
      "Debe aceptar los Términos y Condiciones",
    "You must agree to the": "Debe estar de acuerdo con el",
    "I agree to": "Debes estar de acuerdo con el",
    "Privacy Policy": "Política de privacidad",
    "Terms & Conditions": "Términos y condiciones de uso",
    "You must agree to the Terms & Conditions and to the Privacy Policy":
      "Debe aceptar los Términos y Condiciones y la Política de Privacidad",
    "and the": "y el",
    "Send code": "Enviar el código",
    Sending: "Envío en curso",
    Submitting: "Envío en curso",
    "New password": "Nueva contraseña",
    "Attempt limit exceeded, please try after some time.":
      "Se ha alcanzado el número máximo de envíos, por favor inténtelo de nuevo después de algún tiempo.",
    "First Name": "Nombre",
    "Middle Name": "Segundo nombre",
    "Last Name": "Apellidos",
    Submit: "Enviar",
    Cancel: "Cancelar",
    "Insurance Number": "Número de seguro",
    Male: "Hombre",
    Female: "Mujer",
    Gender: "Género",
    Other: "Otros",
    "Personal Information": "Datos Personales",
    "Edit profile": "Editar perfil",
    "Professional Information": "Datos Profesionales ",
    Location: "Dirección",
    "Phone Number": "Número de teléfono",
    "Alternative phone": "Teléfono alternativo",
    Specialities: "Especialidades",
    Languages: "Idiomas",
    Resume: "Presentación",
    "Professional number": "Número de colegiado ",
    "Insurance number": "Número de seguro",
    "Phone number": "Número de teléfono",
    "Creating Account": "Creación de la cuenta corriente...",
    "We Emailed You": "Correo electrónico enviado",
    // "Your code is on the way. To log in, enter the code we emailed to":
    //   "Votre code de confirmation à été envoyé, afin de vous connecter, entrez le code envoyé à l'email",
    "It may take a minute to arrive.":
      "El correo electrónico puede tardar unos minutos en llega",
    "We Sent A Code": "Le hemos enviado un código",
    // "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.":
    // "Entrez le code reçu afin de vous connecter. Ce code peut prendre quelques minutes avant d'arriver.",
    "Saisissez cotre code de confirmation":
      "Introduzca su código de confirmación",
    "Invalid verification code provided, please try again.":
      "Código de verificación no válido, por favor, inténtelo de nuevo",
    Confirming: "Confirmación...",
    Title: "Título",
    "Additional address informations":
      "Información adicional sobre la dirección",
    "Country/Region": "País/Región",
    "Alternative phone number": "Número de teléfono alternativo",
    "Language(s)": "Idioma(s)",
    "Character count:": "Recuento de caracteres:",
    Speciality: "Especialidad(es)",
    "Signin in": "Conexión...",
    "Signing in": "Conexión...",
    "Cognito received the following error from Amazon SES when attempting to send email: Email address is not verified. The following identities failed the check in region EU-WEST-3: ":
      "Cognito recibió el siguiente error de Amazon SES al intentar enviar un correo electrónico: La dirección de correo electrónico no está verificada. Las siguientes identidades no superaron la comprobación en la región EU-WEST-3: ",
    "Cognito received the following error from Amazon SES when attempting to send email: Email address is not verified. The following identities failed the check in region EU-WEST-3:":
      "Cognito recibió el siguiente error de Amazon SES al intentar enviar un correo electrónico: La dirección de correo electrónico no está verificada. La siguiente identidad no pasó la verificación en la región EU-WEST-3:",
    "Incorrect username or password.":
      "Nombre de usuario o contraseña incorrectos.",
    "Signin In": "Iniciar sesión..",
    "Signing In": "Iniciar sesión..",
    "Password attempts exceeded":
      "Número de intentos de inicio de sesión excedido, por favor inténtelo más tarde.",
    "User does not exist.": "El usuario no existe.",
    "Log out": "Cerrar la sesión",
    "Account settings": "Configuración del perfil",

    None: "No especificado",
    de: "Alemán",
    en: "Inglés",
    ar: "Árabe",
    es: "Español",
    fr: "Francés",
    in: "Hindi",
    it: "Italiano",
    jp: "Japonés",
    md: "Mandarín",
    pt: "Portugués",
    ru: "Ruso",
    tu: "Turco",
    gr: "Griego",
    Acupuncturist: "Acupunturista",
    Engiologist: "Angiólogo",
    Cardiologist: "Cardiólogo",
    physiotherapist: "Fisioterapeuta",
    "Orthopaedic Surgeon": "Cirujano ortopédico",
    "Surgeon Orthopaedic": "Cirujano ortopédico",
    Dentist: "Dentista",
    ENT: "Otorrinolaringólogo",
    Dermatologist: "Dermatólogo",
    Nutritionist: "Nutricionista",
    Dietitian: "Dietético",
    Endocrinologist: "Endocrinólogo",
    Occupational: "Terapeuta ocupacional",
    Chiropractor: "Etiopata",
    Gynaecologist: "Ginecólogo",
    Homeopath: "Homeópata",
    Hypnotherapist: "Hipnoterapeuta",
    Nurse: "Enfermero",
    "General Practitioner": "Médico generalista",
    Naturopath: "Naturópata",
    Nephrologist: "Nefrólogo",
    Neurologist: "Neurólogo",
    "Speech Therapist": "Logopeda",
    Podiatrist: "Podólogo",
    Psychiatrist: "Psiquiatra",
    Pediatrician: "Pediatre",
    Pedicure: "Pedicura",
    Sophrologist: "Sofrólogo",
    Pharmacy: "Farmacia",
    "Poison Control Center": "Centro de control de venenos",
    Laboratory: "Laboratorio",
    Pulmonologist: "Neumólogo",
    Rheumatologist: "Reumatólogo",
    Urologist: "Urólogo",
    Midwife: "Obstetra",
    Clinic: "Clínico",
    "Preferred Language": "Lengua preferida",
    "Initialization...": "Inicialización...",
    "https://www.mybakup.com/cgupro": "https://www.mybakup.com/cgupro?lang=es",
    "https://www.mybakup.com/privacy-policy":
      "https://www.mybakup.com/privacy-policy?lang=es",
    "Fields are missing or invalid.":
      "Campos no completos o datos incorrectos.",
    Settings: "Parámetros",
    "See profile": "Ver perfil",
    "Require at least one uppercase letter from Latin alphabet (A–Z)":
      "Se requiere al menos una letra mayúscula del alfabeto (A-Z)",
    "Require at least one lowercase letter from Latin alphabet (a–z)":
      "Se requiere al menos una letra minúscula del alfabeto (a-z)",
    "Require at least one number": "Se requiere al menos un número",
    "Require at least one nonalphanumeric character ! @ # $ % ^ & * ( ) _ + - = [ ] {} | '":
      "Se requiere al menos un carácter no alfanumérico ! @ # $ % ^ & * ( ) _ + - = [ ] {} | '",
    "Current Password": "Contraseña actual",
    "New Password": "Nueva contraseña",
    "Confirm New Password": "Confirmar nueva contraseña",
    "Passwords not matches": "Las contraseñas no coinciden",
    "Change password": "Cambiar contraseña",
    "Password updated successfully": "Contraseña actualizada con éxito",
    "Error while updating password": "Error al actualizar la contraseña",
    "Profile picture not set": "Todavía no hay imagen",
    "Profile picture": "Foto de perfil",
    "Office pictures not set":
      "Las imágenes del consultorio no fueron seleccionadas",
    "Office pictures": "Fotos del consultorio",
    "* Max picture size (MB):": "* Tamaño máximo de la imagen (MB) :",
    Back: "Volver",
    "Are you sure you want to change language?":
      "¿Estás seguro de que quieres cambiar de idioma?",
    "The data entered that has not been saved will be lost after the language change.":
      "Los datos introducidos que no se hayan guardado se perderán tras el cambio de idioma.",
    Confirm: "Confirmar",

    "Cher / Chère": "ESTIMADO/A",
    "vous venez de finaliser la première partie de votre inscription":
      "ACABA DE COMPLETAR LA PRIMERA PARTE DE SU INSCRIPCIÓN",
    "L'équipe Bakup vous remercie de votre confiance !":
      "¡EL EQUIPO DE BAKUP LE AGRADECE POR SU CONFIANZA!",
    "Nous sommes actuellement en train de finaliser la prise de rendez-vous en ligne, la téléconsultation ainsi que beaucoup d'autres fonctionnalités pour vous permettre de prendre en charge vos patients le plus efficacement possible":
      "Actualmente estamos ultimando las funciones de reserva de citas en línea, teleconsulta y muchas otras para que pueda gestionar sus pacientes con la mayor eficacia posible.",
    "Lors de la sortie de l'application, cette page sera votre tableau de bord !":
      "Cuando la aplicación sea lanzada, ¡esta página será su dashboard!",
    "Depuis cette page et le centre de notification, vous pourrez donc suivre le développement de la solution et vous serez avertis de la sortie des nouvelles fonctionnalités.":
      "​​​​Desde esta página y el centro de notificaciones podrá seguir el desarrollo de la solución y se le notificará cuando se publiquen nuevas funciones.",
    "C'est également depuis cette page que vous verrez les demandes de rendez-vous auxquels vous pourrez répondre pour prendre en charge vos patients.":
      "Desde esta página también podrá ver las demandas de citas a las que puede responder para atender a sus pacientes.",
    "Nous vous invitons donc à toujours veiller à ce que les informations de votre profil soient constamment mise à jour comme le mentionnent les CGU.":
      "Por lo tanto, le invitamos a que se asegure siempre de que la información de su perfil esté constantemente actualizada, tal como se menciona en los T&C",
    "Bakup est actuellement présent dans 18 destinations et continue son développement":
      "BAKUP ESTÁ ACTUALMENTE PRESENTE EN 18 DESTINOS Y SIGUE CRECIENDO",

    "Vos avantages avec Bakup :": "Sus ventajas con Bakup :",
    "35 spécialités": "35 especialidades",
    "référencées sur la plateforme": "referenciado en la plataforma",
    "Gagner en visibilité auprès ": "Ganar visibilidad frente a los",
    "des acteurs du tourisme": "actores del sector turístico",
    "Aider les voyageurs": "Ayudar a los viajeros",
    à: "a",
    "profiter sereinement de leur séjour":
      "disfrutar de su viaje con tranquilidad",
    "Améliorer l'accès aux soins":
      "Mejorar el acceso a la asistencia sanitaria",
    "sur votre secteur auprès des voyageurs et": "a los viajeros de su zona y",
    "de désengorger les services d'urgences":
      "para descongestionar los servicios de emergencia",
    "Développer ": "Desarrollar",
    "votre réseau": "su red",
    "Intégrer une plateforme 100% gratuite":
      "Integrar una plataforma 100% gratuita",
    "(incluant la téléconsultation à venir)":
      "(incluida la teleconsulta que vendrá)",
    "Vous êtes visible auprès des": "USTED ES VISIBLE POR LOS VIAJEROS",
    "voyageurs en fonction :": "EN FUNCION:",
    "De votre": "De su",
    géolocalisation: "localization ",
    Des: "De les ",
    "tarifs pratiqués": "tarifas aplicadas",
    "De vos": "De sus",
    // "de vos": "de sus",

    "langues parlées": "idiomas hablados",
    disponibilités: "disponibilidad ",
    qualifications: "calificaciones ",
    "Merci pour votre confiance et à très vite !":
      "¡GRACIAS POR SU CONFIANZA Y HASTA MUY  PRONTO!",
    "L'équipe Bakup": "El equipo de Bakup",
    "Preferred Communication Language": "Lengua de comunicación preferida",
    "We will use this language to communicate with you.":
      "Utilizaremos esta lengua para comunicar con usted",
    "No options": "No hay opciones",
    "Enter your code": "Introduzca su código",
    "Hide map": "Ocultar mapa",
    "Display map": "Mostrar mapa",
    "Coutry code required": "Código de país requerido",
    "Location required": "Ubicación requerida",
    "First name is required": "El nombre es obligatorio",
    "Last name is required": "Los apellidos sont obligatorios",
    "Language required": "Idioma requerido",
    "Speciality required": "Se requiere una especialidad",
    "Country code required": "Código de país requerido",
    "Pr.": "Prof.",
    "Vous êtes visible auprès des voyageurs en fonction de votre":
      "Usted es visible para los viajeros en función de su",
    "géolocalisation, ": "geolocalización, ",
    "des ": "des ",
    "tarifs pratiqués, ": "tarifas, ",
    "de vos ": "de sus ",
    "langues parlées, ": "idiomas hablados, ",
    "disponibilités, ": "disponibilidad, ",
    "qualifications.": "calificaciones.",
    "Reset your password": "Restablecer la contraseña",
    "Enter your email": "Introduzca su correo electrónico",
    Email: "Correo electrónico",
    Pr: "Prof",
    "Presentation and qualifications": "Presentación y calificaciones",
    English: "Inglés",
    Français: "Francés",
    Physiotherapist: "Fisioterapeuta",
    "Cannot reset password for the user as there is no registered/verified email or phone_number":
      "No se puede restablecer la contraseña del usuario porque no hay correo electrónico o número de teléfono registrado/ verificado",
    "Username/client id combination not found.":
      "No se ha encontrado la combinación nombre de usuario/identificación de cliente.",
    "Your profile picture": "Su foto de perfil",
    "Gender required": "Género requerido",
    Ophtalmologist: "Oftalmólogo",
    "Password does not conform to policy: Password not long enough":
      "La contraseña no se ajusta a la política: La contraseña no es lo suficientemente larga",
    Hematologist: "Hematólogo",
    Changing: "Cambiar...",
    Profile: "Perfil",
    Orthodontist: "Ortodoncista",
    "Dental Surgeon": "Cirujano dental",
    Psychologist: "Psicólogo",
    "Invalid email": "Email inválido",
  },
};
