import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import { Logout } from "@mui/icons-material";

import { Auth, I18n } from "aws-amplify";

import { ReactComponent as Logo } from "../../assets/bakup-logo-green.svg";

import "./MobileDrawer.css";
import { Link } from "@aws-amplify/ui-react";
import SettingsIcon from "@mui/icons-material/Settings";
import { useGlobalContext } from "../../commons/ContextProvider/ContextProvider";

type Anchor = "left";

type ISwipeableMobileDrawer = {
  handleGoToProfile: () => void;
};
export default function SwipeableMobileDrawer(props: ISwipeableMobileDrawer) {
  const { handleGoToProfile } = props;
  const [state, setState] = React.useState({
    left: false,
  });

  const { setDisplaySettings } = useGlobalContext();

  const handleSignOut = async () => {
    await Auth.signOut();
    window.location.reload();
  };
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const handleDisplaySettings = () => {
    if (setDisplaySettings) {
      setDisplaySettings(true);
    }
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: 300,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button>
          <ListItemIcon>
            <Link
              href="/"
              isExternal={false}
              // className="logoContainer"
            >
              <Logo className={"mobileDrawerLogo"} />
            </Link>
          </ListItemIcon>
        </ListItem>
        {/* <ListItem button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItem> */}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleGoToProfile}>
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={I18n.get("Profile")} />
        </ListItem>
        <ListItem button onClick={handleDisplaySettings}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={I18n.get("Settings")} />
        </ListItem>
        <ListItem button onClick={handleSignOut}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary={I18n.get("Logout")} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div className="mobileDrawerContainer">
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {anchor === "left" ? (
              <MenuIcon className={"mobileMenuIcon"} />
            ) : (
              <ClearIcon className={"mobileMenuIcon"} />
            )}
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
