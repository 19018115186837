import { Flex } from "@aws-amplify/ui-react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { I18n } from "aws-amplify";

import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import "../Profile.css";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Box, FormHelperText } from "@mui/material";
import ProfileImages from "../ProfileImages";

interface IProps {
  personalType: string;
  setPersonalType: React.Dispatch<React.SetStateAction<string>>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  middleName: string;
  setMiddleName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  gender: string;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  setFormsValid: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitted: boolean;
}
const EditProfilePersonalForm = (props: IProps) => {
  const {
    personalType,
    setPersonalType,
    firstName,
    setFirstName,
    middleName,
    setMiddleName,
    lastName,
    setLastName,
    gender,
    setGender,
    setFormsValid,
    isSubmitted,
  } = props;

  const fieldsUpdated = useRef({
    personalType: false,
    firstName: false,
    middleName: false,
    lastName: false,
    gender: false,
  });

  const [formErrors, setFormErrors] = useState({
    personalType: " ",
    firstName: " ",
    middleName: " ",
    lastName: " ",
    gender: " ",
    personalTypeValid: false,
    firstNameValid: false,
    middleNameValid: false,
    lastNameValid: false,
    genderValid: false,
  });
  const [fieldsError, setFieldsError] = useState({
    firstName: false,
    lastName: false,
    gender: false,
  });
  // const nameRegEx = /[^A-Za-z -]/g; // Match anything that is not A-Z, a-z, or space

  useEffect(() => {
    if (isSubmitted) {
      setFieldsError({
        firstName: !Boolean(firstName),
        lastName: !Boolean(lastName),
        gender: !Boolean(gender),
      });
    }
  }, [isSubmitted]);

  function handleOnBlur(
    e: any,
    fieldName: string // Field name string verification?
  ) {
    const { value } = e.target;

    switch (fieldName) {
      case "personalType":
        fieldsUpdated.current.personalType = true;

        if (value !== "Dr" || value !== "Pr" || value !== null) {
          setFormErrors({
            ...formErrors,
            personalType: I18n.get("Error"),
            personalTypeValid: false,
          });
        }
        break;

      case "firstName":
        fieldsUpdated.current.firstName = true;

        if (!value) {
          setFormErrors({
            ...formErrors,
            firstName: I18n.get("First name is required"),
            firstNameValid: false,
          });
        } else if (value.length > 50) {
          setFormErrors({
            ...formErrors,
            firstName: `${I18n.get(
              "First name too long, maximum length is"
            )} 50 ${I18n.get("characters")}.`,
            firstNameValid: false,
          });
          // } else if (nameRegEx.test(value)) {
          //   // We should research how to handle characters like は or é
          //   // TODO: remove ' ' or '-' at the start and end of a string
          //   setFormErrors({
          //     ...formErrors,
          //     firstName:
          //       "Please use allowed characters: a-z, A-Z, '-' and spaces.",
          //     firstNameValid: false,
          //   });
        } else {
          setFormErrors({
            ...formErrors,
            firstName: "",
            firstNameValid: true,
          });
        }
        break;

      case "middleName":
        fieldsUpdated.current.middleName = true;

        if (value.length > 50) {
          setFormErrors({
            ...formErrors,
            firstName: `${I18n.get(
              "Middle name too long, maximum length is"
            )} 50 ${I18n.get("characters")}.`,
            firstNameValid: false,
          });
        }
        // else if (nameRegEx.test(value)) {
        //   setFormErrors({
        //     ...formErrors,
        //     middleName:
        //       "Please use allowed characters: a-z, A-Z, '-' and spaces.",
        //     middleNameValid: false,
        //   });
        // }
        else {
          setFormErrors({
            ...formErrors,
            middleName: "",
            middleNameValid: true,
          });
        }

        break;
      case "lastName":
        fieldsUpdated.current.lastName = true;

        if (!value) {
          setFormErrors({
            ...formErrors,
            lastName: I18n.get("Last name is required"),
            lastNameValid: false,
          });
        } else if (value.length > 50) {
          setFormErrors({
            ...formErrors,
            lastName: `${I18n.get(
              "Last name too long, maximum length is"
            )} 50 ${I18n.get("characters")}.`,
            lastNameValid: false,
          });
        }
        // else if (nameRegEx.test(value)) {
        //   setFormErrors({
        //     ...formErrors,
        //     lastName:
        //       "Please use allowed characters: a-z, A-Z, '-' and spaces.",
        //     lastNameValid: false,
        //   });
        // }
        else {
          setFormErrors({
            ...formErrors,
            lastName: "",
            lastNameValid: true,
          });
        }
        break;

      case "gender":
        fieldsUpdated.current.gender = true;

        if (!value && !gender) {
          setFormErrors({
            ...formErrors,
            gender: I18n.get("Gender is required"),
            genderValid: false,
          });
        } else {
          setFormErrors({
            ...formErrors,
            gender: "",
            genderValid: true,
          });
        }
        break;

      default:
        break;
    }
  }

  const handleRequiredFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "gender":
        setGender(value);
        break;
      default:
        break;
    }
    setFieldsError({ ...fieldsError, [name]: !Boolean(value) });
  };

  return (
    <>
      <FormControl>
        <Flex direction={"column"}>
          <InputLabel id="titleSelectLabel">{I18n.get("Title")}</InputLabel>
          <Select
            placeholder={I18n.get("Select a title")}
            labelId="titleSelectLabel"
            id="titleSelect"
            value={personalType ? personalType : ""}
            label="personalType"
            onChange={(e) => {
              setPersonalType(e.target.value);
            }}
          >
            <MenuItem value="">{I18n.get("None")}</MenuItem>
            <MenuItem value={"Dr"}>{I18n.get("Dr.")}</MenuItem>
            <MenuItem value={"Pr"}>{I18n.get("Pr.")}</MenuItem>
          </Select>
          <FormHelperText id="titleSelect-helper-text" className="errorText">
            {fieldsUpdated.current.personalType && formErrors.personalTypeValid
              ? " "
              : " "}
          </FormHelperText>

          <Flex className="mobileColumn">
            <TextField
              className={"halfProfileFormField"}
              required
              id="firstName"
              name="firstName"
              label={I18n.get("First Name")}
              // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              //   setFirstName(e.target.value)
              // }
              onChange={handleRequiredFieldChange}
              placeholder={I18n.get("First Name")}
              value={firstName}
              // onBlur={(e) => handleOnBlur(e, "firstName")}
              // error={
              //   fieldsUpdated.current.firstName &&
              //   formErrors.firstName !== "" &&
              //   formErrors.firstName !== " "
              //     ? true
              //     : false
              // }
              // helperText={
              //   formErrors.firstNameValid ? " " : formErrors.firstName
              // }
              error={fieldsError.firstName}
              helperText={
                fieldsError.firstName
                  ? I18n.get("First name is required")
                  : undefined
              }
            />

            <TextField
              id="middleName"
              label={I18n.get("Middle Name")}
              className="halfProfileFormField middleName"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMiddleName(e.target.value);
              }}
              placeholder={I18n.get("Middle Name")}
              value={middleName}
              helperText={
                formErrors.middleNameValid ? " " : formErrors.middleName
              }
              onBlur={(e) => handleOnBlur(e, "middleName")}
              error={
                fieldsUpdated.current.middleName &&
                formErrors.middleName !== "" &&
                formErrors.middleName !== " "
                  ? true
                  : false
              }
            />
          </Flex>
          <TextField
            required
            label={I18n.get("Last Name")}
            id="lastName"
            name="lastName"
            // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            //   setLastName(e.target.value)
            // }
            onChange={handleRequiredFieldChange}
            placeholder={I18n.get("Last Name")}
            value={lastName}
            // helperText={formErrors.lastNameValid ? " " : formErrors.lastName}
            // onBlur={(e) => handleOnBlur(e, "lastName")}
            // error={
            //   fieldsUpdated.current.lastName && formErrors.lastName
            //     ? true
            //     : false
            // }
            error={fieldsError.lastName}
            helperText={
              fieldsError.lastName
                ? I18n.get("Last name is required")
                : undefined
            }
          />

          <FormControl
            sx={{ mt: 2 }}
            error={fieldsError.gender === true ? true : false}
          >
            <FormLabel id="gender-form">
              {I18n.get("Gender")}
              {" *"}
            </FormLabel>
            <RadioGroup
              onChange={(e) => {
                setGender(e.target.value || "");
                handleOnBlur(e, "gender");
                handleRequiredFieldChange(e);
              }}
              onBlur={(e) => handleOnBlur(e, "gender")}
              row
              aria-labelledby="gender"
              name="gender"
              value={gender}
            >
              <FormControlLabel
                value="Male"
                control={<Radio {...props} color="primary" />}
                label={I18n.get("Male")}
              />
              <FormControlLabel
                value="Female"
                control={<Radio {...props} color="primary" />}
                label={I18n.get("Female")}
              />
            </RadioGroup>

            <FormHelperText id="gender-helper-text" className="errorText">
              {fieldsError.gender === false ? " " : I18n.get("Gender required")}
            </FormHelperText>
          </FormControl>
        </Flex>
      </FormControl>
      <Box>
        <ProfileImages />
      </Box>
    </>
  );
};

export default EditProfilePersonalForm;
