/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cognito_identity_pool_id": "eu-west-3:c358098a-5a08-4e95-aaf7-1c967855050e",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_iJ8d8gN0Z",
    "aws_user_pools_web_client_id": "5ak0t5niknk40neoso5no6647m",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://xxspiivb5fdupjrdzq3upxla6i.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ipermazvx5df3ovellpcxjd7yy",
    "aws_user_files_s3_bucket": "bakups3v0103357-staging",
    "aws_user_files_s3_bucket_region": "eu-west-3",
    "geo": {
        "amazon_location_service": {
            "region": "eu-west-1",
            "maps": {
                "items": {
                    "mapb1b3db02-staging": {
                        "style": "VectorEsriStreets"
                    },
                    "mapbdb66850-staging": {
                        "style": "VectorEsriNavigation"
                    },
                    "esriWorldStreets-staging": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "esriWorldStreets-staging"
            },
            "search_indices": {
                "items": [
                    "placeindex5ad99e53-staging"
                ],
                "default": "placeindex5ad99e53-staging"
            }
        }
    }
};


export default awsmobile;
