import { I18n } from "aws-amplify";

import { CognitoUserAmplify, translations } from "@aws-amplify/ui";
import { Flex, Heading } from "@aws-amplify/ui-react";

import { Box, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import "./Profile.css";

import { useEffect, useRef, useState } from "react";
import { dict } from "../../utils/i18n-dict";
import EditProfilePersonalForm from "./EditProfilePersonalForm";
import EditProfileProfessionalForm from "./EditProfilProfessionalForm";

import { AlertColor } from "@mui/material";
import CustomSnackbar from "../../commons/CustomSnackbar";
import {
  createCurrentDoctor,
  getCurrentDoctor,
  updateCurrentDoctor,
} from "./consumers";
import Homepage from "../../pages";
import { useGlobalContext } from "../../commons/ContextProvider/ContextProvider";
import Settings from "../Settings";
import { getPhoneIfOptional } from "./utils";

export interface ILanguage {
  label: string;
  value: string;
}
export interface ISpeciality {
  label: string;
  value: string;
}
export interface IAdress {
  country: string;
  geometry: {
    point: number[];
  };
  label: string;
  municipality: string;
  region: string;
  subRegion: string;
  additionalAddress: string;
}
const DEFAULT_ADDRESS: IAdress = {
  country: "",
  geometry: {
    point: [],
  },
  label: "",
  municipality: "",
  region: "",
  subRegion: "",
  additionalAddress: "",
};

// enum CurrentPageEnum {
//   PROFILE = "profile",
//   ADVANTAGE = "advantage",
// }

I18n.putVocabularies(translations);
I18n.putVocabularies(dict);

interface IProps {
  user: CognitoUserAmplify;
  lang: string;
  handleGoToProfile: () => void;
  setCurrentPage: (page: string) => void;
  CurrentPageEnum: any;
  currentPage: string;
}
const Profile = (props: IProps) => {
  const {
    user,
    lang,
    handleGoToProfile,
    setCurrentPage,
    CurrentPageEnum,
    currentPage,
  } = props;

  const [snackBarMessage, setSnackBarMessage] = useState<{
    severity: AlertColor | undefined;
    message: string;
  } | null>(null);

  // User always already exist because we set create it at first connexion
  // const [userAlreadyExist, setUserAlreadyExist] = useState(false);

  // Personal information state
  const [personalType, setPersonalType] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [gender, setGender] = useState<string>("");

  // Professional information state
  const [countryCodePhoneNumber, setCountryCodePhoneNumber] =
    useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [
    countryCodeAlternativePhoneNumber,
    setCountryCodeAlternativePhoneNumber,
  ] = useState<any>("");
  const [alternativePhone, setAlternativePhone] = useState<string>("");
  const [specialities, setSpecialities] = useState<string[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [resume, setResume] = useState<string>("");
  const [professionalNumber, setProfessionalNumber] = useState<string>("");
  const [insurance, setInsurance] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedLocationData, setSelectedLocationData] =
    useState<IAdress>(DEFAULT_ADDRESS);
  const [isDataInitialized, setIsDataInitialized] = useState(false);

  const [additionalAddressInfos, setAdditionalAddressInfos] =
    useState<string>("");
  const [preferredLanguage, setPreferredLanguage] = useState<string>(lang);
  // const [currentPage, setCurrentPage] = useState(CurrentPageEnum.PROFILE);

  // Form validation state
  const [formsValid, setFormsValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { account, setAccount, displaySettings } = useGlobalContext();

  const fetchCurrentDoctor = () => {
    if (user?.username) {
      setIsDataInitialized(false);

      getCurrentDoctor({
        id: user.username,
      })
        .then(async (res) => {
          if (res?.data?.getDoctors) {
            const editUser = res.data.getDoctors;
            setPersonalType(editUser?.title || "");
            setFirstName(editUser?.first_name || "");
            setLastName(editUser?.last_name || "");
            setMiddleName(editUser?.middle_name || "");
            setFirstName(editUser?.first_name || "");
            setInsurance(editUser?.insurance_number || "");
            setProfessionalNumber(
              editUser?.professional_identification_number || ""
            );
            setLanguages(editUser.languages);

            setGender(editUser.gender);
            setResume(editUser?.resume || "");

            setSpecialities(editUser.specialties);

            setProfessionalNumber(
              editUser?.professional_identification_number || ""
            );

            const phones = JSON.parse(editUser?.phones || "");

            if (phones) {
              phones.length > 0 &&
                phones[0].phoneNumber &&
                setPhoneNumber(phones[0].phoneNumber);
              phones.length > 0 &&
                phones[0].countryCodePhoneNumber &&
                setCountryCodePhoneNumber(phones[0].countryCodePhoneNumber);
              phones.length > 1 &&
                phones[1].alternativePhone &&
                setAlternativePhone(phones[1].alternativePhone);
              phones.length > 1 &&
                phones[1].countryCodeAlternativePhoneNumber &&
                setCountryCodeAlternativePhoneNumber(
                  phones[1].countryCodeAlternativePhoneNumber
                );
            }

            setPreferredLanguage(editUser.preferred_language || lang); // auto-detect browser language?

            // try to parse address
            try {
              const locationData = JSON.parse(
                editUser?.address || JSON.stringify(DEFAULT_ADDRESS)
              );
              setSelectedLocationData(locationData);
              setAdditionalAddressInfos(locationData.additionalAddress || "");
            } catch {
              setSelectedLocationData(DEFAULT_ADDRESS);
            }

            // setProfilStatus
            // setUserAlreadyExist(true);
            if (
              editUser.profile_status &&
              editUser.profile_status === "SAVED"
            ) {
              // User already saved his/her profile (if created by retool: CREATED_BY_TEAM or EDITED_BY_TEAM)
              setCurrentPage(CurrentPageEnum.ADVANTAGE); // @Tsara Disabled autoredirect to advantage page for development
            }

            if (setAccount) {
              setAccount({
                ...account,
                title: editUser.title || "",
                firstName: editUser.first_name || "",
                lastName: editUser.last_name || "",
              });
            }
          } else {
            // Refetch current doctor after 5 sec if doctor info not saved yet into DB (ex lambda to create doctor sleeps)
            await new Promise((resolve) => setTimeout(resolve, 5000));
            fetchCurrentDoctor();
          }
        })
        .catch(async (err: any) => {
          console.log("!! error while fetching doctor", err); // TODO: proper error handling
        })
        .finally(() => {
          setIsDataInitialized(true);
        });
    } else {
      setIsDataInitialized(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCurrentDoctor();
  }, []);

  const handleEdit = async () => {
    setLoading(true);
    setIsSubmitted(true);
    const altPhone = getPhoneIfOptional(
      countryCodeAlternativePhoneNumber,
      alternativePhone
    );

    const input = {
      id: user.username || "",
      title: personalType,
      gender,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      email: (user as any).attributes.email,
      languages: languages,
      preferred_language: preferredLanguage,
      specialties: specialities,
      profile_status: "SAVED",
      address: JSON.stringify({
        ...selectedLocationData,
        additionalAddress: additionalAddressInfos,
      }),
      resume,
      phones: JSON.stringify([
        {
          countryCodePhoneNumber,
          phoneNumber,
        },
        {
          countryCodeAlternativePhoneNumber: altPhone.country,
          alternativePhone: altPhone.number,
        },
      ]),
      insurance_number: insurance,
      professional_identification_number: professionalNumber,
    };

    const parsedInputAddress = JSON.parse(input.address);
    const parsedInputPhones = JSON.parse(input.phones);

    if (
      // !formsValid ||
      !input.first_name ||
      input.first_name.length > 50 ||
      (input.middle_name && input.middle_name.length > 50) ||
      input.last_name.length > 50 ||
      !input.last_name ||
      !input.gender ||
      !parsedInputAddress.label ||
      parsedInputAddress.label.length > 300 ||
      !parsedInputAddress.geometry.point[0] === null ||
      !parsedInputAddress.geometry.point[0] === undefined ||
      !parsedInputPhones[0].countryCodePhoneNumber ||
      parsedInputPhones[0].countryCodePhoneNumber.length > 30 ||
      !parsedInputPhones[0].phoneNumber ||
      parsedInputPhones[0].phoneNumber.length > 20 ||
      (parsedInputPhones[1].countryCodePhoneNumber &&
        !parsedInputPhones[1].phoneNumber) ||
      (parsedInputPhones[1].phoneNumber &&
        parsedInputPhones[1].phoneNumber.length > 20) ||
      (parsedInputPhones[1].phoneNumber &&
        !parsedInputPhones[1].countryCodePhoneNumber) ||
      !input.specialties.length ||
      !input.languages.length ||
      !input.preferred_language ||
      input.resume.length > 2000 ||
      input.insurance_number.length > 128 ||
      input.professional_identification_number.length > 128 ||
      !input.email ||
      !input.id
    ) {
      setSnackBarMessage({
        message: I18n.get("Fields are missing or invalid."),
        severity: "error",
      });
      setLoading(false);
    } else {
      // WIP

      // if (!userAlreadyExist) {
      //   createCurrentDoctor({
      //     input,
      //   })
      //     .then(async (data: any) => {
      //       setSnackBarMessage({
      //         message: I18n.get("Successfully updated profile"),
      //         severity: "success",
      //       });
      //       console.log("data");
      //       console.log(data);
      //     })
      //     .catch((err) => console.log(err))
      //     .finally(() => setLoading(false));
      // } else {

      // User always already exist because we set create it at first connexion
      updateCurrentDoctor({
        input,
      })
        .then((data: any) => {
          // console.log("!! Saved data", data);

          setSnackBarMessage({
            message: I18n.get("Successfully updated profile"),
            severity: "success",
          });

          if (setAccount) {
            setAccount({
              ...account,
              title: input.title,
              firstName: input.first_name,
              lastName: input.last_name,
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);

          setCurrentPage(CurrentPageEnum.ADVANTAGE);
        });
      // }
    }
  };

  // const handleGoToProfile = () => {
  //   setCurrentPage(CurrentPageEnum.PROFILE);
  // };

  // Display settings page
  if (displaySettings) {
    return <Settings />;
  }

  return (
    <>
      {currentPage === CurrentPageEnum.PROFILE ? (
        <>
          {isDataInitialized ? (
            <Flex margin={25} direction={"column"} className="doctorProfile">
              <Heading className="profilePageHeading">
                {I18n.get("Edit profile")}
              </Heading>
              <Flex className="profileFormContainer">
                <Flex flex={1} direction={"column"}>
                  <Heading className="profileHeading personalInformationHeading">
                    {I18n.get("Personal Information")}
                  </Heading>
                  <EditProfilePersonalForm
                    personalType={personalType}
                    setPersonalType={setPersonalType}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    middleName={middleName}
                    setMiddleName={setMiddleName}
                    lastName={lastName}
                    setLastName={setLastName}
                    gender={gender}
                    setGender={setGender}
                    setFormsValid={setFormsValid}
                    isSubmitted={isSubmitted}
                  />
                </Flex>
                <Flex flex={1} direction={"column"}>
                  <Heading className="profileHeading">
                    {I18n.get("Professional Information")}
                  </Heading>
                  <EditProfileProfessionalForm
                    lang={lang}
                    userId={user?.username || ""}
                    // userAlreadyExist={userAlreadyExist}
                    additionalAddressInfos={additionalAddressInfos}
                    setAdditionalAddressInfos={setAdditionalAddressInfos}
                    phoneNumber={phoneNumber}
                    countryCodePhoneNumber={countryCodePhoneNumber}
                    countryCodeAlternativePhoneNumber={
                      countryCodeAlternativePhoneNumber
                    }
                    setCountryCodePhoneNumber={setCountryCodePhoneNumber}
                    setCountryCodeAlternativePhoneNumber={
                      setCountryCodeAlternativePhoneNumber
                    }
                    setPhoneNumber={setPhoneNumber}
                    alternativePhone={alternativePhone}
                    setAlternativePhone={setAlternativePhone}
                    specialities={specialities}
                    setSpecialities={setSpecialities}
                    languages={languages}
                    setLanguages={setLanguages}
                    professionalNumber={professionalNumber}
                    setProfessionalNumber={setProfessionalNumber}
                    insurance={insurance}
                    setInsurance={setInsurance}
                    resume={resume}
                    setResume={setResume}
                    selectedLocationData={selectedLocationData}
                    setSelectedLocationData={setSelectedLocationData}
                    preferredLanguage={preferredLanguage}
                    setPreferredLanguage={setPreferredLanguage}
                    setFormsValid={setFormsValid}
                    isSubmitted={isSubmitted}
                  />
                  <Flex
                    justifyContent={"flex-end"}
                    className="profileFormActionButtonsContainer"
                  >
                    <Button variant="outlined">{I18n.get("Cancel")}</Button>
                    <LoadingButton
                      loading={isLoading}
                      onClick={handleEdit}
                      sx={{
                        minWidth: "20%",
                        color: "white",
                        ml: ".5%",
                      }}
                      variant="contained"
                    >
                      {isLoading
                        ? `${I18n.get("Submitting")}...`
                        : I18n.get("Submit")}
                    </LoadingButton>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <div
              style={{
                paddingTop: "5%",
                margin: "auto",
                textAlign: "center",
                color: "grey",
              }}
            >
              {I18n.get("Loading...")}
            </div>
          )}

          <CustomSnackbar
            open={!!snackBarMessage}
            autoHideDuration={5000}
            severity={snackBarMessage?.severity}
            onClose={() => setSnackBarMessage(null)}
            message={snackBarMessage?.message}
          />
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Homepage handleGoToProfile={handleGoToProfile} />
        </Box>
      )}
    </>
  );
};

export default Profile;
