import AddPhotoIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { I18n, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../../commons/ContextProvider/ContextProvider";
import { getAccountProfileImageFolder } from "../../../../utils/account";

const StyledIconButton = styled(IconButton)<
  IconButtonProps & { component?: string } // Explicitely add "component" props to prevent TS error
>(({ color, theme }) => ({
  color: "white",
  backgroundColor: color === "error" ? theme.palette.error.main : "#1fc0b4",
  "&:hover": {
    backgroundColor: color === "error" ? theme.palette.error.dark : "#1dada3",
  },
}));

const Input = styled("input")({
  display: "none",
});

const ProfilePhoto = () => {
  const [profileUrl, setProfileUrl] = useState("");
  const [profileKey, setProfileKey] = useState("");
  const [loading, setLoading] = useState(false);

  const { account, setProfileUrl: setContextProfileUrl } = useGlobalContext();

  const profileImageFolder = getAccountProfileImageFolder(account.id);

  const fetchProfileUrl = async () => {
    try {
      setLoading(true);
      const results = await Storage.list(`${profileImageFolder}/`, {
        level: "private",
      });
      if (results) {
        const profileResult = results[0];

        if (profileResult.key) {
          setProfileKey(profileResult.key);
          const storedProfileUrl = await Storage.get(profileResult.key, {
            level: "private",
          });
          setProfileUrl(storedProfileUrl);
          if (setContextProfileUrl) {
            setContextProfileUrl(storedProfileUrl);
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileUrl();
  }, []);

  const handleUploadFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event?.target?.files) {
      const profileFile = event.target.files[0];

      const fileExtension = profileFile.type.split("/")[1];
      const key = `${profileImageFolder}/profile.${fileExtension}`;

      try {
        setLoading(true);
        // First delete previous one if exist
        if (profileKey) {
          await Storage.remove(profileKey, { level: "private" });
        }

        await Storage.put(key, profileFile, { level: "private" });

        fetchProfileUrl();
      } catch (err) {
        console.log("!! error uploading file", err);
      } finally {
        setLoading(false);
      }
      event.target.value = "";
    }
  };

  const handleDeleteProfile = async () => {
    if (profileKey) {
      try {
        setLoading(true);
        await Storage.remove(profileKey, { level: "private" });
        setProfileUrl("");
        if (setContextProfileUrl) {
          setContextProfileUrl(""); // Update context to update user's avatar images
        }
      } catch (err) {
        console.log("!! error deleting file", err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="h6">{I18n.get("Profile picture")}</Typography>
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <Box display="flex">
            <StyledIconButton
              aria-label={`Delete profile picture`}
              color="error"
              disabled={!Boolean(profileUrl) || loading}
              onClick={handleDeleteProfile}
              sx={{ mr: 1 }}
            >
              {profileUrl && <DeleteIcon />}
            </StyledIconButton>
            <label htmlFor="upload-profile-img">
              <Input
                accept="image/*"
                id="upload-profile-img"
                type="file"
                onChange={handleUploadFile}
              />
              <StyledIconButton
                component="span"
                aria-label={`Add profile picture`}
                disabled={loading}
              >
                <AddPhotoIcon />
              </StyledIconButton>
            </label>
          </Box>
        )}
      </Box>
      {!loading && !Boolean(profileUrl) ? (
        <Alert severity="info">
          <Typography variant="body1">
            {I18n.get("Profile picture not set")}
          </Typography>
        </Alert>
      ) : (
        <>
          {profileUrl ? (
            <Box
              sx={{
                width: "100%",
                overflow: "auto",
              }}
              className={"profilePicture"}
            >
              <img
                alt={I18n.get(`Your profile picture`)}
                src={profileUrl}
                style={{ maxHeight: 200, maxWidth: "100%" }}
                loading="lazy"
              />
            </Box>
          ) : null}
        </>
      )}
    </>
  );
};

export default ProfilePhoto;
