/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDoctors = /* GraphQL */ `
  query GetDoctors($id: ID!) {
    getDoctors(id: $id) {
      id
      title
      gender
      first_name
      middle_name
      last_name
      email
      languages
      preferred_language
      phones
      resume
      address
      specialties
      profile_status
      insurance_number
      professional_identification_number
      avatar_picture
      other_pictures
      prospection_email_status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDoctors = /* GraphQL */ `
  query ListDoctors(
    $filter: ModelDoctorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        gender
        first_name
        middle_name
        last_name
        email
        languages
        preferred_language
        phones
        resume
        address
        specialties
        profile_status
        insurance_number
        professional_identification_number
        avatar_picture
        other_pictures
        prospection_email_status
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($type: String!) {
    getSettings(type: $type) {
      type
      values
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $type: String
    $filter: ModelSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSettings(
      type: $type
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        type
        values
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
