import React, { useState } from "react";

import { Auth, I18n } from "aws-amplify";
import {
  CognitoUserAmplify,
  SignUpAttribute,
  translations,
} from "@aws-amplify/ui";

import {
  Authenticator,
  CheckboxField,
  useAuthenticator,
  Text,
} from "@aws-amplify/ui-react";

import { dict } from "../utils/i18n-dict";

import "@aws-amplify/ui-react/styles.css";
import "./AuthenticatorForm/AuthenticatorForm.css";
import CheckUser from "./CheckUser";
import Link from "@mui/material/Link";
import { emailValidator } from "../utils/validator";

// Amplify.configure(awsconfig);
I18n.putVocabularies(translations);
I18n.putVocabularies(dict);
interface IAppProps {
  setLang: any; //TODO
  lang: string;
  setUser: React.Dispatch<React.SetStateAction<CognitoUserAmplify>>;
  setCurrentPage: any;
  currentPage: string;
  handleGoToProfile: () => void;
  CurrentPageEnum: any;
}

function warnUser() {
  setTimeout(
    console.log.bind(
      console,
      `%c${I18n.get("STOP")}!`,
      "color:#d81d1d;line-height: 10rem; font-size: 5rem;"
    )
  );
  setTimeout(
    console.log.bind(
      console,
      `%c${I18n.get(
        "Please don't run anything provided by someone else here as they could steal your account!"
      )}`,

      "color:#ffffff;line-height: 1rem; font-size: 1rem;"
    )
  );
}
warnUser();

// https://ui.docs.amplify.aws/components/authenticator#signupattributes
const authenticatorSignUpAttributes: SignUpAttribute[] = ["email", "locale"];

export default function MainApp(props: IAppProps) {
  const {
    lang,
    setUser,
    setCurrentPage,
    CurrentPageEnum,
    handleGoToProfile,
    currentPage,
  } = props;
  const [signUpFormEmailFirstChar, setSignUpFormEmailFirstChar] =
    useState(null);
  const [signUpFormEmailDomainFirstChar, setSignUpFormEmailDomainFirstChar] =
    useState(null);

  function replaceUnstanslatableString() {
    // console.log(`!!$$ triggered replaceUnstanslatableString`);
    const untranslatableText = document.querySelector(
      "form .amplify-text"
    ) as HTMLElement;
    // console.log(`#### untranslatableText`, untranslatableText);

    if (
      untranslatableText?.textContent?.includes(
        "Your code is on the way. To log in, enter the code we emailed to"
      )
    ) {
      untranslatableText.textContent =
        I18n.get(
          "Your code is on the way. To log in, enter the code we emailed to "
        ) +
        `${signUpFormEmailFirstChar}***@${signUpFormEmailDomainFirstChar}***. ` +
        I18n.get("It may take a minute to arrive.");
    }
  }

  return (
    <Authenticator
      socialProviders={[]}
      // socialProviders={["facebook", "google"]}
      key={lang}
      initialState="signIn"
      className="AuthenticatorForm"
      signUpAttributes={authenticatorSignUpAttributes}
      components={{
        SignUp: {
          FormFields() {
            const { validationErrors } = useAuthenticator();
            return (
              <>
                {/* Re-use default `Authenticator.SignUp.FormFields` */}
                <Authenticator.SignUp.FormFields />

                {/* Append & require Terms & Conditions field to sign up  */}
                <CheckboxField
                  errorMessage={validationErrors.acknowledgement}
                  hasError={!!validationErrors.acknowledgement}
                  name="acknowledgement"
                  value="yes"
                  label={
                    <Text>
                      {I18n.get("I agree to the")}{" "}
                      <Link
                        className={"selectedLinkBorder"}
                        href={I18n.get("https://www.mybakup.com/cgupro")}
                        // isExternal={true}
                        target="_blank"
                        rel="noopener"
                        underline="hover"
                      >
                        {I18n.get("Terms & Conditions")}
                      </Link>{" "}
                      {I18n.get("and the")}{" "}
                      <Link
                        className={"selectedLinkBorder"}
                        href={I18n.get(
                          "https://www.mybakup.com/privacy-policy"
                        )}
                        // isExternal={true}
                        target="_blank"
                        rel="noopener"
                        underline="hover"
                      >
                        {I18n.get("Privacy Policy")}
                      </Link>
                    </Text>
                  }
                />
              </>
            );
          },
        },
        // https://docs.amplify.aws/ui/auth/authenticator/q/framework/react/#confirm-sign-up
        // ConfirmSignUp: {
        // Footer() {
        //   return <>{replaceUnstanslatableString()}</>;
        // },
        // },
      }}
      services={{
        async validateCustomSignUp(formData) {
          const { email, password, confirm_password } = formData;
          if (password.length !== 0 && password === confirm_password) {
            if (!emailValidator(email)) {
              return {
                acknowledgement: I18n.get("Invalid email"),
              };
            }
            if (Boolean(email) && !formData.acknowledgement) {
              return {
                acknowledgement: I18n.get(
                  "You must agree to the Terms & Conditions and to the Privacy Policy"
                ),
              };
            }
          }
          if (!formData.acknowledgement) {
            return {
              acknowledgement: I18n.get(""),
            };
          }
        },
        // async handleConfirmSignUp(formData: any) {
        //   // https://ui.docs.amplify.aws/components/authenticator#override-function-calls
        //   let { username, code } = formData;
        //   replaceUnstanslatableString();
        //   return Auth.confirmSignUp(username, code);
        // },

        async handleSignUp(formData) {
          let { username, password, attributes } = formData;
          setSignUpFormEmailFirstChar(username[0]);
          setSignUpFormEmailDomainFirstChar(username.split("@")[1][0]);
          attributes.locale = lang;
          return Auth.signUp({
            username,
            password,
            attributes,
          });
        },
      }}
    >
      {({ user }) => {
        if (user) {
          setUser(user);
        }
        return (
          <CheckUser
            user={user}
            lang={lang}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            CurrentPageEnum={CurrentPageEnum}
            handleGoToProfile={handleGoToProfile}
          />
        );
      }}
    </Authenticator>
  );
}
